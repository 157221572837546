<template>
  <div class="container-fluid px-0" ref="vehicleTab">
    <div class="row gx-10">
      <div class="col-4">
        <div class="system-block">
          <div class="mb-15 d-flex">
            <span class="fsc-12 fw-bolder">{{
              dataLang['vehicles-page__modal__tab-object__column-1__header']
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between mb-10">
            <span class="fsc-11 text-muted input-title">
              {{ dataLang['vehicles-page__modal__tab-object__column-1__city'] }}
            </span>
            <BaseSelect
              style="width: 302px"
              @input="setSelected"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="dataFromSelect.dataCity"
              id="city"
              label="cities__name"
              :options="optionsForSelect.cities"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template>
              <template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicles__place_id"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang['vehicles-page__modal__tab-object__column-1__place']
              }}
            </span>
            <BaseSelect
              style="width: 302px"
              @input="setSelected"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="dataFromSelect.dataPlace"
              id="places"
              label="vehicle_places__name"
              :options="optionsForSelect.places"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicles__mark_id"
          >
            <span class="fsc-11 text-muted input-title">
              {{ dataLang['vehicles-page__modal__tab-object__column-1__mark'] }}
            </span>
            <BaseSelect
              style="width: 302px"
              @input="setSelected"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="dataFromSelect.dataMark"
              id="mark"
              label="vehicle_marks__name"
              :options="optionsForSelect.marks"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicles__model_id"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang['vehicles-page__modal__tab-object__column-1__model']
              }}
            </span>
            <BaseSelect
              style="width: 302px"
              @input="setSelected"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="dataFromSelect.dataModel"
              id="model"
              label="vehicles__model"
              :options="optionsForSelect.models"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicle_cars__body_generation"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang[
                  'vehicles-page__modal__tab-object__column-1__body_generation'
                ]
              }}
            </span>
            <BaseSelect
              style="width: 225px"
              @input="setSelected"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              v-model="dataFromSelect.dataGeneration"
              id="generation"
              label="car_examples__body_generation"
              :options="optionsForSelect.generation"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__body_type"
            :value="dataForModal.vehicle_cars__body_type"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__body_type']
            "
          >
          </BaseInputNew>
          <!-- <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__width"
            :value="dataForModal.vehicle_cars__width"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__width']
            "
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__height"
            :value="dataForModal.vehicle_cars__height"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__height']
            "
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__length"
            :value="dataForModal.vehicle_cars__length"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__length']
            "
          >
          </BaseInputNew> -->
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__count_doors"
            :value="dataForModal.vehicle_cars__count_doors"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__doors']
            "
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__count_places"
            :value="dataForModal.vehicle_cars__count_places"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__seats']
            "
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__tank_capacity"
            :value="dataForModal.vehicle_cars__tank_capacity"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__trail']
            "
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__ground_clearance"
            :value="dataForModal.vehicle_cars__ground_clearance"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__clearence']
            "
          >
          </BaseInputNew>
          <!-- <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__curb_weight"
            :value="dataForModal.vehicle_cars__curb_weight"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__mass']
            "
          >
          </BaseInputNew>
          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__full_weight"
            :value="dataForModal.vehicle_cars__full_weight"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__full_mass']
            "
          >
          </BaseInputNew> -->

          <BaseInputNew
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="mb-10"
            input-name="vehicle_cars__tank_capacity"
            :value="dataForModal.vehicle_cars__tank_capacity"
            :name-sign="
              dataLang['vehicles-page__modal__tab-object__column-1__tank']
            "
          >
          </BaseInputNew>
          <BaseTextarea
            placeholder=""
            :disabled="true"
            :width="'225'"
            class="fsc-11"
            inputClass="fsc-11"
            @onInput="updateValue"
            minHeight="126"
            :maxLength="499"
            :resize="false"
            input-name="vehicles__adv_description"
            :value="dataForModal.vehicles__adv_description"
            :name-sign="
              dataLang[
                'vehicles-page__modal__tab-object__column-1__adv_description'
              ]
            "
          >
          </BaseTextarea>
          <span class="fsc-8 text-muted">{{ lengthDescription }} / 500</span>
          <!-- <BaseCheckboxV2Vue placeholder="" v-for="(item, index) in listAccessPage" :key="index"
                                input-name="checkboxx" v-model="listUpdateAccessPage[index].employee_menu_rules__state"
                                @oncheckeds="updateValueListAccess(listUpdateAccessPage[index])">Люк</BaseCheckboxV2Vue> -->
        </div>
      </div>
      <div class="col-4">
        <div class="system-block">
          <div class="mb-15 d-flex">
            <span class="fsc-12 fw-bolder"
              >{{
                dataLang['vehicles-page__modal__tab-object__column-2__header']
              }}
            </span>
          </div>
          <div class="mb-10" ref="vehicles__year_release">
            <BaseInputNew
              placeholder=""
              :width="'225'"
              @input="updateValue"
              input-name="vehicles__year_release"
              :value="dataForModal.vehicles__year_release"
              inputRef="vehicles__year_release"
              inputMask="0000"
              maxLength="4"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-2__year']
              "
            >
            </BaseInputNew>
            <!-- <span class="fsc-11 text-muted input-title">
              {{ dataLang['vehicles-page__modal__tab-object__column-2__year'] }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataYears"
              label="label"
              :options="optionsForSelect.years"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect> -->
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicle_cars__drive"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang['vehicles-page__modal__tab-object__column-2__drive']
              }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataDrive"
              label="label"
              :options="selectsData.vehicle_cars__drive"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicles__color"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang['vehicles-page__modal__tab-object__column-2__color']
              }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataColor"
              label="label"
              :options="selectsData.vehicles__color"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicle_cars__kpp"
          >
            <span class="fsc-11 text-muted input-title">
              {{ dataLang['vehicles-page__modal__tab-object__column-2__kpp'] }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataKpp"
              id="city"
              label="label"
              :options="selectsData.vehicle_cars__kpp"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div class="d-flex align-items-center gap-5 wp-100 mb-10">
            <div
              class="d-flex align-items-center justify-content-between flex-grow-1"
              ref="vehicles__type_engine"
            >
              <span class="fsc-11 text-muted input-title">
                {{
                  dataLang[
                    'vehicles-page__modal__tab-object__column-2__engine_type'
                  ]
                }}
              </span>
              <BaseSelect
                style="width: 110px"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                @input="setSimpleSelect"
                v-model="dataFromSelect.dataTypeEngine"
                label="label"
                :options="selectsData.vehicles__type_engine"
              >
                <div slot="no-options">Пока нет опций</div>
                <div slot="no-result">Ничего не найдено</div>
              </BaseSelect>
            </div>
            <div ref="vehicles__type_petrol">
              <BaseSelect
                style="width: 110px"
                placeholder=""
                selectLabel=""
                deselectLabel=""
                @input="setSimpleSelect"
                v-model="dataFromSelect.dataPetrol"
                label="label"
                :options="selectsData.vehicles__type_petrol"
              >
                <template slot="noOptions">{{
                  dataLang['select_empty']
                }}</template>
                <template slot="noResult">{{
                  dataLang['select_search_empty']
                }}</template
                ><template slot="selectedLabel">{{
                  dataLang['select_selected']
                }}</template>
                <template slot="deselectLabel">{{
                  dataLang['select_unselect']
                }}</template>
              </BaseSelect>
            </div>
          </div>

          <div ref="vehicles__engine_volume">
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              input-name="vehicles__engine_volume"
              @input="updateValue"
              :value="dataForModal.vehicles__engine_volume"
              :name-sign="
                dataLang[
                  'vehicles-page__modal__tab-object__column-2__engine_volume'
                ]
              "
              inputRef="vehicles__engine_volume"
              inputMask="0.0"
            >
            </BaseInputNew>
          </div>
          <div ref="vehicles__engine_power">
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              input-name="vehicles__engine_power"
              @input="updateValue"
              inputRef="vehicles__engine_power"
              inputMask="000000000"
              :value="dataForModal.vehicles__engine_power"
              :name-sign="
                dataLang[
                  'vehicles-page__modal__tab-object__column-2__engine_power'
                ]
              "
            >
            </BaseInputNew>
          </div>
          <div ref="vehicles__flow">
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              input-name="vehicles__flow"
              @input="updateValue"
              inputRef="vehicles__flow"
              inputMask="000000000"
              :value="dataForModal.vehicles__flow"
              :name-sign="
                dataLang[
                  'vehicles-page__modal__tab-object__column-2__fuel_consumption'
                ]
              "
            >
            </BaseInputNew>
          </div>

          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicle_cars__material"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang['vehicles-page__modal__tab-object__column-2__material']
              }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataMaterial"
              label="label"
              :options="selectsData.vehicle_cars__material"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicle_cars__conditioner"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang[
                  'vehicles-page__modal__tab-object__column-2__conditioner'
                ]
              }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataConditioner"
              label="label"
              :options="selectsData.vehicle_cars__conditioner"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>

          <!-- <BaseInputNew placeholder="" :width="'225'" class="mb-10" input-name="vehicle_cars__multimedia"
                        @input="updateValue" :value="dataForModal.vehicle_cars__multimedia"
                        :name-sign="dataLang['vehicles-page__modal__tab-object__column-2__multimedia']">
                    </BaseInputNew> -->
          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicle_cars__multimedia"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang[
                  'vehicles-page__modal__tab-object__column-2__multimedia'
                ]
              }}
            </span>
            <BaseSelect
              style="width: 225px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSimpleSelect"
              v-model="dataFromSelect.dataMultimedia"
              label="label"
              :options="selectsData.vehicle_cars__multimedia"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div ref="vehicles__cost">
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-15"
              input-name="vehicles__cost"
              @input="updateValue"
              :value="dataForModal.vehicles__cost"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-2__cost']
              "
            >
            </BaseInputNew>
          </div>
          <div class="d-flex">
            <div>
              <BaseCheckboxV2
                placeholder=""
                class="mb-10"
                input-name="vehicle_cars__luk"
                v-model="dataForModal.vehicle_cars__luk"
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicles-page__modal__tab-object__column-2__luk']
                }}</BaseCheckboxV2
              >
              <BaseCheckboxV2
                placeholder=""
                class="mb-10"
                input-name="vehicle_cars__farcop"
                v-model="dataForModal.vehicle_cars__farcop"
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicles-page__modal__tab-object__column-2__farkop']
                }}</BaseCheckboxV2
              >
              <BaseCheckboxV2
                placeholder=""
                class="mb-10"
                input-name="vehicle_cars__reyling"
                v-model="dataForModal.vehicle_cars__reyling"
                @oncheckeds="updateValue"
                >{{
                  dataLang[
                    'vehicles-page__modal__tab-object__column-2__reyling'
                  ]
                }}</BaseCheckboxV2
              >
              <BaseCheckboxV2
                placeholder=""
                input-name="vehicle_cars__ton"
                v-model="dataForModal.vehicle_cars__ton"
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicles-page__modal__tab-object__column-2__ton']
                }}</BaseCheckboxV2
              >
            </div>
            <div>
              <BaseCheckboxV2
                placeholder=""
                class="mb-10"
                input-name="vehicle_cars__armor"
                v-model="dataForModal.vehicle_cars__armor"
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicles-page__modal__tab-object__column-2__bron']
                }}</BaseCheckboxV2
              >
              <BaseCheckboxV2
                placeholder=""
                input-name="vehicle_cars__long"
                v-model="dataForModal.vehicle_cars__long"
                @oncheckeds="updateValue"
                >{{
                  dataLang['vehicles-page__modal__tab-object__column-2__long']
                }}</BaseCheckboxV2
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="system-block mb-10">
          <div class="mb-15 d-flex">
            <span class="fsc-12 fw-bolder">{{
              dataLang['vehicles-page__modal__tab-object__column-3__header']
            }}</span>
          </div>
          <div ref="vehicles__gosnumber">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class="mb-10"
              input-name="vehicles__gosnumber"
              @input="updateValue"
              :value="dataForModal.vehicles__gosnumber"
              :name-sign="
                dataLang[
                  'vehicles-page__modal__tab-object__column-3__gosnumber'
                ]
              "
              inputRef="vehicles__gosnumber"
              inputMask="a000aa000"
              maxLength="9"
            >
            </BaseInputNew>
          </div>

          <div ref="vehicles__sts">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class="mb-10"
              input-name="vehicles__sts"
              @input="updateValue"
              :value="dataForModal.vehicles__sts"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-3__sts_text']
              "
            >
            </BaseInputNew>
          </div>
          <div ref="vehicles__sts_date">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class="mb-10"
              input-name="vehicles__sts_date"
              @input="updateValue"
              :value="dataForModal.vehicles__sts_date"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-3__sts_date']
              "
              inputRef="vehicles__sts_date"
              inputMask="00.00.0000"
              maxLength="10"
            >
            </BaseInputNew>
          </div>

          <div
            class="d-flex align-items-center justify-content-between mb-10"
            ref="vehicles__owner_id"
          >
            <span class="fsc-11 text-muted input-title">
              {{
                dataLang['vehicles-page__modal__tab-object__column-3__owner']
              }}
            </span>
            <BaseSelect
              style="width: 190px"
              placeholder=""
              selectLabel=""
              deselectLabel=""
              @input="setSelectedOwner"
              v-model="dataFromSelect.dataOwner"
              :custom-label="
                ({ vehicle_owners__name, vehicle_owner_ur__in_face }) => {
                  return vehicle_owners__name
                    ? vehicle_owners__name
                    : vehicle_owner_ur__in_face
                }
              "
              :options="optionsForSelect.owners"
            >
              <template slot="noOptions">{{
                dataLang['select_empty']
              }}</template>
              <template slot="noResult">{{
                dataLang['select_search_empty']
              }}</template
              ><template slot="selectedLabel">{{
                dataLang['select_selected']
              }}</template>
              <template slot="deselectLabel">{{
                dataLang['select_unselect']
              }}</template>
            </BaseSelect>
          </div>
          <div ref="vehicles__pts">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class="mb-10"
              input-name="vehicles__pts"
              @input="updateValue"
              :value="dataForModal.vehicles__pts"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-3__pts_text']
              "
            >
            </BaseInputNew>
          </div>
          <div ref="vehicles__pts_date">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class="mb-10"
              input-name="vehicles__pts_date"
              @input="updateValue"
              :value="dataForModal.vehicles__pts_date"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-3__pts_date']
              "
              inputRef="vehicles__pts_date"
              inputMask="00.00.0000"
              maxLength="10"

            >
            </BaseInputNew>
          </div>
          <div ref="vehicles__pts_owner">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class="mb-10"
              input-name="vehicles__pts_owner"
              @input="updateValue"
              :value="dataForModal.vehicles__pts_owner"
              :name-sign="
                dataLang[
                  'vehicles-page__modal__tab-object__column-3__pts_owner'
                ]
              "
            >
            </BaseInputNew>
          </div>
          <div ref="vehicles__vin">
            <BaseInputNew
              placeholder=""
              :width="'190'"
              class=""
              input-name="vehicles__vin"
              @input="updateValue"
              :value="dataForModal.vehicles__vin"
              :name-sign="
                dataLang['vehicles-page__modal__tab-object__column-3__pts_vin']
              "
            >
            </BaseInputNew>
          </div>
        </div>
        <div class="system-block mb-10">
          <BaseCheckboxV2
            placeholder=""
            class="mb-10"
            input-name="vehicle_cars__is_active_oil_control"
            v-model="dataForModal.vehicle_cars__is_active_oil_control"
            @oncheckeds="updateValue"
            ><span class="fw-bolder fsc-12 text-dark">{{
              dataLang[
                'vehicles-page__modal__tab-object__column-3__pts_is_oil_control'
              ]
            }}</span>
          </BaseCheckboxV2>
          <div class="d-flex justify-content-between gap-20">
            <div class="flex-grow-1">
              <!-- set_vehicle_interval_to_dvs -->
              <BaseInputNew
                placeholder=""
                :width="'90'"
                input-name="vehicle_cars__range_oil_dvs"
                inverted
                @input="updateValue"
                :value="dataForModal.vehicle_cars__range_oil_dvs"
                :name-sign="
                  dataLang[
                    'vehicles-page__modal__tab-object__column-3__oil_engine'
                  ]
                "
              >
              </BaseInputNew>
            </div>
            <div class="flex-grow-1">
              <!-- set_vehicle_interval_to_akpp -->
              <BaseInputNew
                placeholder=""
                :width="'90'"
                input-name="vehicle_cars__range_oil_kpp"
                @input="updateValue"
                :value="dataForModal.vehicle_cars__range_oil_kpp"
                :name-sign="
                  dataLang[
                    'vehicles-page__modal__tab-object__column-3__oil_kpp'
                  ]
                "
              >
              </BaseInputNew>
            </div>
          </div>
        </div>
        <div class="d-flex gap-10">
          <!-- <div
            class="rounded-5 min-h-172 wp-50 d-flex flex-column justify-content-center align-items-center text-white"
            style="background-color: rgba(35, 177, 77, 1)"
          >
            <span class="fw-bold fsc-36 lh-1">{{
              dataForModal.vehicles__rayting
            }}</span>
            <span class="fsc-18">{{
              dataForModal.vehicles__rayting
                ? dataLang[
                    'vehicles-page__modal__tab-object__column-3__reyting'
                  ]
                : ''
            }}</span>
          </div> -->

          <div
            class="rounded-5 min-h-172 wp-50 d-flex flex-column gap-5 justify-content-center align-items-center text-white"
            v-if="modeOpenModal == 'edit'"
            :class="[
              dataForModal.vehicle_status__code == 'quarantine'
                ? 'bg-danger'
                : ''
            ]"
            :style="{
              backgroundColor:
                dataForModal.vehicle_status__code == 'success'
                  ? 'rgba(35, 177, 77, 1)'
                  : ''
            }"
          >
            <span class="fw-bold fsc-24 lh-1" @click="sendData">
              {{ dataForModal.vehicle_status__status }}</span
            >
            <span class="fsc-18">{{
              dataForModal.vehicle_status__code == 'quarantine' ||
              dataForModal.vehicle_status__code == 'moderation'
                ? dataLang[
                    'vehicles-page__modal__tab-object__column-3__status_small'
                  ]
                : ''
            }}</span>
          </div>
          <div
            class="rounded-5 min-h-172 wp-50 d-flex flex-column gap-5 justify-content-center align-items-center text-white text-center"
            :class="[
              !dataForModal.vehicles__agregator_state ? 'bg-danger' : ''
            ]"
            v-if="modeOpenModal == 'edit'"
            :style="{
              backgroundColor: dataForModal.vehicles__agregator_state
                ? 'rgba(35, 177, 77, 1)'
                : ''
            }"
          >
            <!-- register_objects_car_agregator_status_false -->
            <span
              class="fsc-18 lh-1"
              v-if="dataForModal.vehicles__agregator_state"
            >
              {{ dataLang['register_objects_car_agregator_status_true'] }}
              <br />
              {{ dataLang['register_objects_car_text_1'] }}</span
            >
            <span
              class="fsc-18 lh-1"
              v-if="!dataForModal.vehicles__agregator_state"
            >
              {{ dataLang['register_objects_car_agregator_status_false'] }}
              <br />
              {{ dataLang['register_objects_car_text_1'] }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import Modal from '@/components/Modals.vue'
import NewModal from '@/components/NewModal.vue'
import EventBus from '@/event-bus.js'
import BaseTextarea from '@/components/ui/BaseTextarea.vue'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import CitiesService from '@/api/CitiesService.js'
import OwnersService from '@/api/OwnersService.js'
import VehicleMarksService from '@/api/VehicleMarksService.js'
import SelectsService from '@/api/SelectsService.js'
import PlacesService from '@/api/PlacesService.js'
import VehicleTypeAndCharacteristicsService from '@/api/VehicleTypeAndCharacteristicsService.js'
import TransportVehiclesService from '@/api/TransportVehiclesService.js'
import GlobalSettingService from '@/api/GlobalSettingService.js'
import ObjectsService from '@/api/ObjectsService'
import GeneralService from '@/api/GeneralService'
export default {
  components: {
    Modal,
    NewModal,
    BaseTextarea,
    VueTabs,
    VTab
  },

  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    },

    currentOpenedId: {
      type: Number
    }
  },
  data() {
    return {
      updatedValues: {},
      dataForModal: {},
      editData: {},
      modelName: '',
      markName: '',
      loadingNew: false,
      dataFromSelect: {
        dataCity: [],
        dataPlace: [],
        dataMark: [],
        dataModel: [],
        dataGeneration: [],
        dataYears: [],
        dataColor: [],
        dataDrive: [],
        dataKpp: [],
        dataTypeEngine: [],
        dataLuk: [],
        dataFarcop: [],
        dataReyling: [],
        dataTon: [],
        dataMaterial: [],
        dataConditioner: [],
        dataMaterial: [],
        dataOwner: [],
        dataPetrol: [],
        dataMultimedia: []
      },
      optionsForSelect: {
        cities: [],
        marks: [],
        places: [],
        models: [],
        generation: [],
        owners: [],
        years: []
      },
      selectsData: {
        vehicle_cars__drive: [],
        vehicles__color: [],
        vehicle_cars__kpp: [],
        vehicles__type_engine: [],
        vehicle_cars__luk: [],
        vehicle_cars__farcop: [],
        vehicle_cars__reyling: [],
        vehicle_cars__ton: [],
        vehicle_cars__material: [],
        vehicle_cars__conditioner: [],
        vehicles__type_petrol: [],
        vehicle_cars__multimedia: []
      },
      activeSelectRow: null,
      lengthDescription: 0
    }
  },
  methods: {
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      this.$emit('loadingModal', true)
      //       Создание объекта сейчас невозомжно!
      // Причины:
      // Нет ни одной площадки!
      // Нет ни одного собственника!
      // Создайте минимум по 1 записи в этих разделах и вернитесь сюда.
      if (Object.keys(this.updatedValues).length) {
        if (this.modeOpenModal == 'create') {
          // /caniuse/createObject

          TransportVehiclesService.create(
            Object.assign(this.updatedValues, { vehicles__type: 'car' })
          )
            .then((data) => {
              console.log(data)
              this.validateData('', true)
              this.$toast.success('Запись добавлена')
              EventBus.$emit('contentLoaded')
              EventBus.$emit('updateContentData')
              callback(true, nameTab, oldTab, newTab, flagIsClose)
            })
            .catch((error) => {
              // console.log(error.response.data.errors)
              this.validateData(error.response.data.errors)
              this.$toast.error(error.response.data.message)
              callback(false, nameTab, oldTab, newTab)
            })
        }
        if (this.modeOpenModal == 'edit') {
          TransportVehiclesService.update(
            this.currentOpenedId,
            this.updatedValues
          )
            .then((data) => {
              console.log(data.data.data)
              this.validateData('', true)
              this.$toast.success('Данные успешно обновлены')
              callback(true, nameTab, oldTab, newTab, flagIsClose)
              EventBus.$emit('updateContentData')
            })
            .catch((error) => {
              console.log(error)
              this.validateData(error.response.data.errors)
              this.$toast.error('Ошибка загрузки')
              callback(false, nameTab, oldTab, newTab)
            })
        }
      } else {
        callback(true, '', oldTab, newTab, flagIsClose)
      }
    },
    validateData(listErrorsAndStatus, typeOfClose) {
      this.$emit('loadingModal', false)
      if (listErrorsAndStatus) {
        this.$emit('changeErrorInTab', true)
        Object.keys(listErrorsAndStatus).forEach((elem, index) => {
          if (this.$refs[elem]) {
            this.$refs[elem].classList.add('input-error')
          }
        })
      } else {
        if (typeOfClose && this.modeOpenModal == 'create') {
          this.$modal.hide('vehicleSettingModal')
        }
      }
    },

    clearData() {
      this.updatedValues = {}
      for (const key in this.dataFromSelect) {
        this.dataFromSelect[key] = []
      }
      this.modelName = ''
      this.markName = ''
      console.log(this.dataForModal, this.updatedValues)
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
      if (data.name == 'vehicles__engine_volume') {
        let stringNew = data.value.split('').join('.')
        this.updatedValues[data.name] = stringNew
      }
      if (this.$refs[data.name]) {
        this.$refs[data.name].classList.remove('input-error')
      }
      if (data.name == 'vehicles__adv_description') {
        this.lengthDescription = data.value.length
      }
    },
    setSimpleSelect(data, id) {
      console.log(data, id)
      this.updatedValues[data.key] = data.value
      if (this.$refs[data.key]) {
        this.$refs[data.key].classList.remove('input-error')
      }
    },
    computedDateForSelect(data) {
      if (data['car_examples__body_year_starts']) {
        let start = Number(data['car_examples__body_year_starts'].slice(0, 4))
        let end = Number(data['car_examples__body_year_ends'].slice(0, 4))
        let computedYears = end - start
        for (let index = 0; index < computedYears; index++) {
          console.log(start + index)
          this.optionsForSelect.years.push({
            label: start + index,
            key: 'vehicles__year_release',
            value: start + index
          })
        }
        console.log(this.optionsForSelect.years)
      }
    },
    setSelectedOwner(data) {
      this.updatedValues['vehicles__owner_id'] = data.vehicle_owners__id
      this.updatedValues['vehicle_owners__name'] = data.vehicle_owners__name
      if (this.$refs['vehicles__owner_id']) {
        this.$refs['vehicles__owner_id'].classList.remove('input-error')
      }
    },
    setSelected(dataFromSelect) {
      if (dataFromSelect) {
        switch (dataFromSelect.selectName) {
          case 'cities':
            console.log(dataFromSelect)
            // this.dataForModal = Object.assign(this.dataForModal, { cities__id: dataFromSelect.cities__id, cities__name: dataFromSelect.cities__name })
            GeneralService.get(
              `/places/select?objectType=car&where=[{"field":"vehicle_places__city_id","value":${dataFromSelect.cities__id}}]`
            )
              .then((data) => {
                console.log(data)
                this.optionsForSelect.places = this.prepareLabelsForSelects(
                  data.data.data.rows,
                  'place'
                )
              })
              .catch((err) => console.log(err))
            this.dataFromSelect.dataPlace = []
            break

          case 'place':
            console.log('place', dataFromSelect)
            this.updatedValues.vehicles__place_id =
              dataFromSelect.vehicle_places__id
            this.updatedValues.vehicles__company_id =
              dataFromSelect.vehicle_places__company_id
            this.$refs['vehicles__place_id'].classList.remove('input-error')
            // this.updatedValues.vehicle_places__address = dataFromSelect.vehicle_places__address
            console.log(this.updatedValues)
            break

          case 'marks':
            this.markName = dataFromSelect.vehicle_marks__name
            this.updatedValues.vehicles__mark_id =
              dataFromSelect.vehicle_marks__id
            VehicleTypeAndCharacteristicsService.getAllByParams({
              params: {
                where: JSON.stringify([
                  {
                    field: 'vehicle_examples__mark_id',
                    value: dataFromSelect['vehicle_marks__id']
                  },
                  { field: 'vehicle_examples__type_id', value: 1 }
                ])
              }
            })
              .then((data) => {
                this.optionsForSelect.models = this.prepareLabelsForSelects(
                  data.data.data.rows,
                  'models'
                )
              })
              .catch((err) => console.log(err))
            this.dataFromSelect.dataModel = []
            this.dataFromSelect.dataGeneration = []
            // clear data for generation years
            this.optionsForSelect.years = []
            this.dataFromSelect.dataYears = []
            this.fillAfterSelectGeneration()
            break
          case 'models':
            this.updatedValues.vehicles__model = dataFromSelect.vehicles__model
            this.modelName = dataFromSelect.vehicles__model
            VehicleTypeAndCharacteristicsService.getAll({
              params: {
                vehicleType: 'car',
                fields: 'vehicle_examples__id,car_examples__body_generation',
                where: JSON.stringify([
                  {
                    field: 'vehicle_examples__mark_id',
                    value: dataFromSelect['id']
                  },
                  {
                    field: 'vehicle_examples__model',
                    value: dataFromSelect['vehicles__model']
                  }
                ])
              }
            })
              .then((data) => {
                console.log(data)
                this.optionsForSelect.generation = this.prepareLabelsForSelects(
                  data.data.data.rows,
                  'generation'
                )
              })
              .catch((err) => console.log(err))
            this.updatedValues.vehicles__name = `${this.markName} ${this.modelName}`
            this.dataFromSelect.dataGeneration = []
            this.optionsForSelect.years = []
            this.dataFromSelect.dataYears = []
            this.fillAfterSelectGeneration()
            break
          case 'generation':
            // this.updatedValues = Object.assign(this.updatedValues, dataFromSelect)
            // this.dataForModal = Object.assign(this.dataForModal, dataFromSelect)
            this.fillAfterSelectGeneration(dataFromSelect)
            this.$refs['vehicle_cars__body_generation'].classList.remove(
              'input-error'
            )
            console.log(dataFromSelect)
            break
        }
      }

      console.log(dataFromSelect)
    },

    fillAfterSelectGeneration(dataFromSelect) {
      console.log(dataFromSelect)
      if (dataFromSelect) {
        this.computedDateForSelect(dataFromSelect)
        this.dataForModal.vehicle_cars__body_generation =
          dataFromSelect.car_examples__body_generation
        this.dataForModal.vehicle_cars__body_type =
          dataFromSelect.car_examples__body_type
        this.dataForModal.vehicle_cars__vehicle_class =
          dataFromSelect.car_examples__vehicle_class
        this.dataForModal.vehicle_cars__width =
          dataFromSelect.car_examples__width
        this.dataForModal.vehicle_cars__height =
          dataFromSelect.car_examples__height
        this.dataForModal.vehicle_cars__length =
          dataFromSelect.car_examples__length
        this.dataForModal.vehicle_cars__count_doors =
          dataFromSelect.car_examples__count_doors
        this.dataForModal.vehicle_cars__count_places =
          dataFromSelect.car_examples__count_places
        this.dataForModal.vehicle_cars__ground_clearance =
          dataFromSelect.car_examples__ground_clearance
        this.dataForModal.vehicle_cars__curb_weight =
          dataFromSelect.car_examples__curb_weight
        this.dataForModal.vehicle_cars__full_weight =
          dataFromSelect.car_examples__full_weight
        this.dataForModal.vehicle_cars__trunk_volume =
          dataFromSelect.car_examples__trank_volume
        this.dataForModal.vehicle_cars__tank_capacity =
          dataFromSelect.car_examples__tank_capacity
        this.fillAfterSelectGenerationUpdateValues(dataFromSelect)
      } else {
        this.dataForModal.vehicle_cars__body_generation = ''
        this.dataForModal.vehicle_cars__body_type = ''
        this.dataForModal.vehicle_cars__vehicle_class = ''
        this.dataForModal.vehicle_cars__width = ''
        this.dataForModal.vehicle_cars__height = ''
        this.dataForModal.vehicle_cars__length = ''
        this.dataForModal.vehicle_cars__count_doors = ''
        this.dataForModal.vehicle_cars__count_places = ''
        this.dataForModal.vehicle_cars__ground_clearance = ''
        this.dataForModal.vehicle_cars__curb_weight = ''
        this.dataForModal.vehicle_cars__full_weight = ''
        this.dataForModal.vehicle_cars__trunk_volume = ''
        this.dataForModal.vehicle_cars__tank_capacity = ''
        this.fillAfterSelectGenerationUpdateValues()
      }
    },

    fillAfterSelectGenerationUpdateValues(dataFromSelect) {
      if (dataFromSelect) {
        this.updatedValues.vehicle_cars__body_generation =
          dataFromSelect.car_examples__body_generation
        this.updatedValues.vehicle_cars__body_type =
          dataFromSelect.car_examples__body_type
        this.updatedValues.vehicle_cars__vehicle_class =
          dataFromSelect.car_examples__vehicle_class
        this.updatedValues.vehicle_cars__width =
          dataFromSelect.car_examples__width
        this.updatedValues.vehicle_cars__height =
          dataFromSelect.car_examples__height
        this.updatedValues.vehicle_cars__length =
          dataFromSelect.car_examples__length
        this.updatedValues.vehicle_cars__count_doors =
          dataFromSelect.car_examples__count_doors
        this.updatedValues.vehicle_cars__count_places =
          dataFromSelect.car_examples__count_places
        this.updatedValues.vehicle_cars__ground_clearance =
          dataFromSelect.car_examples__ground_clearance
        this.updatedValues.vehicle_cars__curb_weight =
          dataFromSelect.car_examples__curb_weight
        this.updatedValues.vehicle_cars__full_weight =
          dataFromSelect.car_examples__full_weight
        this.updatedValues.vehicle_cars__trunk_volume =
          dataFromSelect.car_examples__trunk_volume
        this.updatedValues.vehicle_cars__tank_capacity =
          dataFromSelect.car_examples__tank_capacity
      } else {
        this.updatedValues.vehicle_cars__body_generation = ''
        this.updatedValues.vehicle_cars__body_type = ''
        this.updatedValues.vehicle_cars__vehicle_class = ''
        this.updatedValues.vehicle_cars__width = ''
        this.updatedValues.vehicle_cars__height = ''
        this.updatedValues.vehicle_cars__length = ''
        this.updatedValues.vehicle_cars__count_doors = ''
        this.updatedValues.vehicle_cars__count_places = ''
        this.updatedValues.vehicle_cars__ground_clearance = ''
        this.updatedValues.vehicle_cars__curb_weight = ''
        this.updatedValues.vehicle_cars__full_weight = ''
        this.updatedValues.vehicle_cars__trunk_volume = ''
        this.updatedValues.vehicle_cars__tank_capacity = ''
      }
    },

    prepareLabelsForSelects(data, keyDataFromFetch) {
      for (const key in data) {
        data[key]['selectName'] = keyDataFromFetch
      }
      return data
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },

    prepareKeysForModal(payloadData) {
      this.updatedValues = {}
      this.dataForModal = {}
      console.log(payloadData)
      if (!payloadData) {
        for (const key in this.transportVehiclesData[0]) {
          this.dataForModal[key] = null
          if (key == 'vehicles__sts_file') {
            console.log('id here!!!')
          }
        }
      } else {
        console.log(payloadData, 'loadDataForEdit')
        if (this.modeOpenModal == 'edit') {
          if (payloadData.vehicles__adv_description)
            this.lengthDescription =
              payloadData.vehicles__adv_description.length
          Promise.all([
            PlacesService.getWithID(payloadData.vehicles__place_id)
              .then((data) => {
                console.log(data, 323232322)
                this.optionsForSelect.places = this.prepareLabelsForSelects(
                  data.data.rows,
                  'place'
                )
                console.log(data, 323232231123312321231)
              })
              .catch((err) => console.log(err)),

            VehicleTypeAndCharacteristicsService.getAllByParams({
              params: {
                where: JSON.stringify([
                  {
                    field: 'vehicle_examples__mark_id',
                    value: payloadData['vehicles__mark_id']
                  },
                  {
                    field: 'vehicle_examples__type_id',
                    value: 1
                  }
                ])
              }
            })
              .then((data) => {
                this.optionsForSelect.models = this.prepareLabelsForSelects(
                  data.data.data.rows,
                  'models'
                )
              })
              .catch((err) => console.log(err)),
            VehicleTypeAndCharacteristicsService.getAll({
              params: {
                vehicleType: 'car',
                fields: 'vehicle_examples__id,car_examples__body_generation',
                where: JSON.stringify([
                  {
                    field: 'vehicle_examples__mark_id',
                    value: payloadData['vehicles__mark_id']
                  },
                  {
                    field: 'vehicle_examples__model',
                    value: payloadData['vehicles__model']
                  }
                ])
              }
            })
              .then((data) => {
                console.log(data)
                this.optionsForSelect.generation = this.prepareLabelsForSelects(
                  data.data.data.rows,
                  'generation'
                )
                this.computedDateForSelect(data.data.data.rows[0])
              })
              .catch((err) => console.log(err))
          ]).then(() => {
            this.$emit('loadingModal', false)
            console.log(payloadData)
            this.dataForModal = Object.assign({}, payloadData)
            console.log(this.optionsForSelect.places, 322323)
            this.dataForModal.vehicles__pts_date = this.dataForModal
              .vehicles__pts_date
              ? this.dataForModal.vehicles__pts_date
                  .split(' ')[0]
                  .split('-')
                  .reverse()
                  .join('.')
              : ''

            this.dataForModal.vehicles__sts_date = this.dataForModal
              .vehicles__sts_date
              ? this.dataForModal.vehicles__sts_date
                  .split(' ')[0]
                  .split('-')
                  .reverse()
                  .join('.')
              : ''
            this.dataFromSelect = {
              dataYears: { label: payloadData.vehicles__year_release },
              dataCity: {
                cities__name: this.optionsForSelect.cities.find(
                  (citiesId) =>
                    citiesId.cities__id ==
                    this.optionsForSelect.places.find(
                      (x) =>
                        x.vehicle_places__id == payloadData.vehicles__place_id
                    ).vehicle_places__city_id
                ).cities__name
              },
              dataPlace: {
                vehicle_places__name: this.optionsForSelect.places.find(
                  (x) => x.vehicle_places__id == payloadData.vehicles__place_id
                ).vehicle_places__name
              },
              dataMark: {
                vehicle_marks__name: payloadData.vehicles__name
                  ? payloadData.vehicles__name.trim().split(' ')[0]
                  : ''
              },
              dataModel: { vehicles__model: payloadData.vehicles__model },
              dataGeneration: {
                car_examples__body_generation:
                  payloadData.vehicle_cars__body_generation
              },
              dataColor: { label: payloadData.vehicles__color },
              dataDrive: { label: payloadData.vehicle_cars__drive },
              dataKpp: { label: payloadData.vehicle_cars__kpp },
              dataTypeEngine: { label: payloadData.vehicles__type_engine },
              dataLuk: { label: payloadData.vehicle_cars__luk },
              dataFarcop: { label: payloadData.vehicle_cars__farcop },
              dataReyling: { label: payloadData.vehicle_cars__reyling },
              dataTon: { label: payloadData.vehicle_cars__ton },
              dataMaterial: { label: payloadData.vehicle_cars__material },
              dataConditioner: { label: payloadData.vehicle_cars__conditioner },
              dataPetrol: { label: payloadData.vehicles__type_petrol },
              dataOwner: {
                // vehicles__owner_id
                vehicle_owners__name: payloadData.vehicles__owner_id
                  ? this.optionsForSelect.owners.find((x) => {
                      console.log(x, payloadData)
                      return (
                        x.vehicle_owners__id == payloadData.vehicles__owner_id
                      )
                    }).vehicle_owners__name
                  : ''
              },
              dataMultimedia: {
                // this.optionsForSelect.owners.find(
                //   (x) => x.vehicle_owners__id == payloadData.vehicles__owner_id
                // ).vehicle_owners__name,
                label: payloadData.vehicle_cars__multimedia
              }
            }
            console.log(this.optionsForSelect.cities, 222323)
          })
        }
      }
    },

    ...mapActions('transportVehiclesModule', [
      'getHealth',
      'getTitle',
      'getTransportVehiclesAll',
      'editTransportVehiclesMultiple',
      'createTransportVehiclesData',
      'deleteTransportVehiclesData'
    ])
  },
  mounted() {},

  created() {
    this.$emit('loadingModal', true)
    if (this.modeOpenModal == 'edit') {
      this.getHealth(this.currentOpenedId)
      this.getTitle(this.currentOpenedId)
    }
    Promise.all([
      CitiesService.getAll({
        params: {
          limit: 3000
        }
      })
        .then((data) => {
          this.optionsForSelect.cities = this.prepareLabelsForSelects(
            data.data.data.rows,
            'cities'
          )
        })
        .catch((e) => console.log(e)),
      VehicleMarksService.getAll()
        .then((data) => {
          this.optionsForSelect.marks = this.prepareLabelsForSelects(
            data.data.data.rows,
            'marks'
          )
        })
        .catch((e) => console.log(e)),
      OwnersService.getAll()
        .then((data) => {
          this.optionsForSelect.owners = this.prepareLabelsForSelects(
            data.data.data.rows,
            'owners'
          )
        })
        .catch((e) => console.log(e)),

      SelectsService.getAll()
        .then((data) => {
          this.selectsData = this.prepareLabelsForDefaultSelects(
            data.data.data.rows,
            'allSelects',
            true
          )
        })
        .catch((e) => console.log(e)),
      GlobalSettingService.getAll()
        .then((data) => {
          if (this.modeOpenModal == 'create') {
            this.dataForModal.vehicle_cars__range_oil_dvs =
              data.data.data.rows[0].company_settings__set_vehicle_interval_to_dvs
            this.dataForModal.vehicle_cars__range_oil_kpp =
              data.data.data.rows[0].company_settings__set_vehicle_interval_to_akpp
            this.updatedValues.vehicle_cars__range_oil_dvs =
              data.data.data.rows[0].company_settings__set_vehicle_interval_to_dvs
            this.updatedValues.vehicle_cars__range_oil_kpp =
              data.data.data.rows[0].company_settings__set_vehicle_interval_to_akpp
          }
        })
        .catch((e) => console.log(e))
    ]).then(() => {
      if (this.modeOpenModal == 'create') {
        this.$emit('loadingModal', false)
      }
      if (this.modeOpenModal == 'edit') {
        ObjectsService.getOne(this.currentOpenedId)
          .then((data) => {
            console.log(data.data.data.rows[0])
            this.prepareKeysForModal(data.data.data.rows[0])
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        console.log('This create')
      }
      this.loadingNew = true
    })
  },
  computed: {
    ...mapState('transportVehiclesModule', [
      'transportVehiclesData',
      'transportVehiclesLoadStatus',
      'paginationStateParams'
    ]),
    ...mapState('translationModule', ['dataLang']),
    ...mapGetters('transportVehiclesModule', ['numPages'])
  }
}
</script>

<style lang="scss" scoped></style>
