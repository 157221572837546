<template>
  <div class="position-relative">
    <BaseTopLine> </BaseTopLine>
    <div
      class="py-10 px-15 d-flex gap-15 align-items-center border-top"
      style="background-color: #1d1e2c"
      ref="topFilter"
    >
      <div class="d-flex gap-5 flex-column pe-10 border-end border-muted">
        <span class="text-white fsc-11">{{
          dataLang['reg_contract_main_1']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 100px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            inputName="contracts__id"
            value=""
            nameSign=""
          />
        </div>
      </div>
      <div class="d-flex gap-5 flex-column pe-10 border-end border-muted">
        <span class="text-white fsc-11">{{
          dataLang['reg_contract_main_2']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <!-- filterTimes -->
          <BaseDatePicker
            v-model="filterTimes.contractsCreateStart"
            type="date"
            class="fsc-11 idiots-class-for-input-date top-filter-input-date"
            lang="ru"
            style="width: 100px"
            value-type="format"
            :format="`${userSetting.company__tempalte_date}`"
            @change="(date) => (filterTimes.contractsCreateStart = date)"
          ></BaseDatePicker>
          -
          <BaseDatePicker
            v-model="filterTimes.contractsCreateEnd"
            type="date"
            class="fsc-11 idiots-class-for-input-date top-filter-input-date"
            lang="ru"
            style="width: 100px"
            value-type="format"
            :format="`${userSetting.company__tempalte_date}`"
            @change="(date) => (filterTimes.contractsCreateEnd = date)"
          ></BaseDatePicker>
        </div>
      </div>
      <div class="d-flex gap-5 flex-column pe-10 border-end border-muted">
        <span class="text-white fsc-11">{{
          dataLang['reg_contract_main_3']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 112px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="vehicles__gosnumber"
            value=""
            nameSign=""
          />
        </div>
      </div>
      <div class="d-flex gap-5 flex-column pe-10 border-end border-muted">
        <span class="text-white fsc-11">{{
          dataLang['reg_contract_main_4']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseInputNew
            placeholder=""
            style="width: 112px"
            @input="fillFilter"
            inputClass="bg-transparent text-white"
            :additionalParams="{ type: 'like' }"
            inputName="clients__name"
            value=""
            nameSign=""
          />
        </div>
      </div>
      <div class="d-flex gap-5 flex-column pe-10 border-end border-muted">
        <span class="text-white fsc-11">{{
          dataLang['reg_contract_main_5']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseDatePicker
            v-model="filterTimes.startsRateTime"
            type="date"
            class="fsc-11 idiots-class-for-input-date top-filter-input-date"
            lang="ru"
            style="width: 100px"
            value-type="format"
            :format="`${userSetting.company__tempalte_date}`"
          ></BaseDatePicker>
          -
          <BaseDatePicker
            v-model="filterTimes.endRateTime"
            type="date"
            class="fsc-11 idiots-class-for-input-date top-filter-input-date"
            lang="ru"
            style="width: 100px"
            value-type="format"
            :format="`${userSetting.company__tempalte_date}`"
          ></BaseDatePicker>
        </div>
      </div>
      <div class="d-flex gap-5 flex-column pe-10 border-end border-muted">
        <span class="text-white fsc-11">{{
          dataLang['reg_contract_main_6']
        }}</span>
        <div class="d-flex gap-5 flex-grow-1">
          <BaseDatePicker
            v-model="filterTimes.startsRateFinishTime"
            type="date"
            class="fsc-11 idiots-class-for-input-date top-filter-input-date"
            lang="ru"
            style="width: 100px"
            value-type="format"
            :format="`${userSetting.company__tempalte_date}`"
          ></BaseDatePicker>
          -
          <BaseDatePicker
            v-model="filterTimes.endRateFinishTime"
            type="date"
            class="fsc-11 idiots-class-for-input-date top-filter-input-date"
            lang="ru"
            style="width: 100px"
            value-type="format"
            :format="`${userSetting.company__tempalte_date}`"
          ></BaseDatePicker>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center gap-5">
        <span class="fsc-11 text-white">{{
          dataLang['reg_contract_main_7']
        }}</span>
        <BaseCheckboxV2
          placeholder=""
          input-name="contracts__is_damage"
          signClass="d-none"
          inverted
          @oncheckeds="
              fillFilter
          "
        >
        </BaseCheckboxV2>
      </div>
      <div class="btn btn-sm btn-primary fsc-11 px-8" @click="loadData">
        {{ dataLang['reg_contract_main_10'] }}
      </div>
    </div>
    <div
      class="position-relative h-100 container-fluid"
      style="min-height: 250px"
    >
      <BaseLoader v-if="localLoading"></BaseLoader>
      <div
        class="d-flex justify-content-between align-items-center mt-15 mb-10"
      >
        <div class="d-flex gap-10">
          <div
            class="fsc-9 text-white bg-danger d-flex justify-content-center align-items-center px-10"
            style="max-width: 209px; width: 100%"
          >
            {{ dataLang['reg_contract_main_11'] }}
          </div>
          <div class="base-button base-button--action" @click="">
            {{ dataLang['reg_contract_main_12'] }}
          </div>
          <div class="base-button base-button--action">
            {{ dataLang['reg_contract_main_13'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="$modal.show('modalSendSomeSMS')"
          >
            {{ dataLang['reg_contract_main_14'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="
              activeSelectRowsMultiple.length
                ? $modal.show('sendSmsPreset')
                : ''
            "
          >
            {{ dataLang['reg_contract_main_15'] }}
          </div>
          <div
            class="base-button base-button--action"
            @click="$modal.show('modalSmsPresets')"
          >
            {{ dataLang['reg_contract_modal_2'] }}
          </div>
          <div class="base-button base-button--action" @click="loadExel">
            <img
              :src="require(`@/assets/icons/to-exel.svg`)"
              width="13.5"
              height="13.5"
            />
            {{ dataLang['reg_contract_main_16'] }}
          </div>
        </div>
      </div>
      <div class="d-flex gap-15 align-items-center justify-content-end">
        <div
          class="text-decoration-underline fsc-14 text-dark"
          style="cursor: pointer"
          @click="resetFilter"
        >
          {{ dataLang['register_btn_filter_off'] }}
        </div>
        <div
          class="btn btn-sm btn-thrity text-white fsc-14"
          @click="loadData(1)"
        >
          {{ dataLang['register_btn_filter_on'] }}
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-15">
        <div class="d-flex align-items-center fs-11 gap-15">
          <div class="">
            {{ dataLang['reg_contract_main_17'] }}:
            <span class="fw-bold">{{ statisticsTopTable?.total }}</span>
          </div>
          <div class="">
            {{ dataLang['reg_contract_main_18'] }}:
            <span class="fw-bold">{{ statisticsTopTable?.newClient }}</span>
            ({{
              (
                (statisticsTopTable?.newClient / statisticsTopTable?.total) *
                100
              ).toFixed(2)
            }}%)
          </div>
          <div class="">
            {{ dataLang['reg_contract_main_19'] }}:
            <span class="fw-bold">{{ statisticsTopTable?.oldClient }}</span>
            ({{
              (
                (statisticsTopTable?.oldClient / statisticsTopTable?.total) *
                100
              ).toFixed(2)
            }}%)
          </div>
          <div class="">
            {{ dataLang['reg_contract_main_20'] }}:
            <span class="fw-bold">{{ statisticsTopTable?.sirius }}</span>
            ({{
              (
                (statisticsTopTable?.sirius / statisticsTopTable?.total) *
                100
              ).toFixed(2)
            }}%)
          </div>
        </div>

        <!-- {
    "total": 76,
    "newClient": 76,
    "oldClient": 0,
    "sirius": 34,
    "service": 0
} -->
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationFinesStateParams"
          :showLimitInput="false"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <div class="text-muted fs-11 mb-5" style="max-width: 461px">
        {{ dataLang['reg_contract_main_21'] }}
      </div>
      <div class="overflow-auto" style="min-height: 400px">
        <ve-table
          style="width: 100%"
          :columns="columns"
          :table-data="dataTable"
          :border-x="true"
          :border-y="true"
          row-key-field-name="contracts__id"
          ref="mainTable"
          :eventCustomOption="eventCustomOption"
          v-if="!localLoading"
          :checkbox-option="{
            selectedRowChange: selectedRowChange,
            selectedAllChange: selectedAllChange,
            hideSelectAll: false
          }"
          :clickHighlight="false"
          :cell-style-option="cellStyleOption"
          :rowStyleOption="{
            hoverHighlight: true
          }"
          :cellSelectionOption="{ enable: false }"
        >
        </ve-table>
      </div>
    </div>

    <BaseModal
      name="selectRow"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('selectRow')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-35">
            {{ dataLang['tracker_text57'] }}
          </p>

          <div class="d-flex justify-content-center mt-15 gap-10">
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('selectRow')"
            >
              {{ dataLang['tracker_text55'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      name="sendSmsPreset"
      width="420"
      @before-close="
        () => {
          activeSelectRowsMultiple = []
          selectsFromModal.sms_presets = []
        }
      "
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('sendSmsPreset')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['reg_contract_main_15'] }}
          </p>
          <BaseSelect
            style="width: 100%"
            placeholder=""
            selectLabel=""
            deselectLabel=""
            @input="(data) => (senderSmsPreset = data)"
            v-model="selectsFromModal.sms_presets"
            :options="selectsDataForModal?.sms_presets"
            label="sms_presets__name"
          >
            <template slot="noOptions">{{ dataLang['select_empty'] }}</template>
            <template slot="noResult">{{
              dataLang['select_search_empty']
            }}</template
            ><template slot="selectedLabel">{{
              dataLang['select_selected']
            }}</template>
            <template slot="deselectLabel">{{
              dataLang['select_unselect']
            }}</template>
          </BaseSelect>
          <div class="d-flex justify-content-center mt-30 gap-30">
            <div
              class="btn btn-sm btn-primary fsc-14 px-20 text-uppercase"
              @click="sendSms"
            >
              {{ dataLang['orders_model_edit__btn_text7'] }}
            </div>
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('sendSmsPreset')"
            >
              {{ dataLang['reg_contract_main_13'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <!-- <BaseModal
      name="modalSendSomeSMS"
      width="420"
      @before-close="
        () => {
          activeSelectRowsMultiple = []
          selectsFromModal.sms_presets = []
        }
      "
      :clickToClose="false"
      :shiftY="0.5"
      :adaptive="true"
      height="295"
      classes="bg-transparent shadow-none"
    >
      <div class="bg-white rounded-25 pt-20 pb-20 px-30 h-100">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('modalSendSomeSMS')"
          ></button>
        </div>
        <div class="d-flex h-100 flex-column justify-content-center pb-10">
          <p class="text-center fw-semi-bold mb-15">
            {{ dataLang['reg_contract_main_15'] }}
          </p>

          <div class="d-flex justify-content-center mt-30 gap-30">
            <div
              class="btn btn-sm btn-primary fsc-14 px-20 text-uppercase"
              @click="sendSms"
            >
              {{ dataLang['orders_model_edit__btn_text7'] }}
            </div>
            <div
              class="base-button base-button--action fsc-14 px-20 text-uppercase"
              @click="$modal.hide('modalSendSomeSMS')"
            >
              {{ dataLang['reg_contract_main_13'] }}
            </div>
          </div>
        </div>
      </div>
    </BaseModal> -->
    <SmsPresetsModal :type-reestr="1" />
    <SendSomeSms />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import GeneralService from '@/api/GeneralService'
import httpInstance from '@/http-common'
import { VueTabs, VTab } from 'vue-nav-tabs/dist/vue-tabs.js'
import SmsPresetsModal from './orders-modal/SmsPresetsModal.vue'
import SendSomeSms from '@/components/reuse_modal/SendSomeSms.vue'

import dayjs from 'dayjs'
export default {
  components: {
    VeTable,
    VueTabs,
    VTab,
    SmsPresetsModal,
    SendSomeSms
  },

  data() {
    return {
      localLoading: true,
      statisticsTopTable: {},
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [{ field: 'contracts__status_id', value: [4], operator: '!=' }],
      activateTab: '',
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      nameValue: '',

      filterTimes: {
        contractsCreateStart: '',
        contractsCreateEnd: '',
        startsRateTime: '',
        endRateTime: '',
        startsRateFinishTime: '',
        endRateFinishTime: ''
      },
      settingOneClick: {},
      activePagePagination: {},
      newValueCustomModal: '',
      currentId: null,
      localLoadingModal: false,
      objectsVehiclesNew: [],
      pincode: '',
      dataForModal: {},
      aboutPageInfo: null,
      fileLoadResult: null,
      dataOfErrors: null,
      senderSmsPreset: null,
      selectsDataForModal: {
        owners: [],
        typeVehicles: [],
        objectsVehicle: [],
        sms_presets: []
      },
      selectsFromModal: {
        owners: [],
        typeVehicles: [],
        objectsVehicle: [],
        sms_presets: []
      },
      selectsData: {
        ads: [],
        typeOrder: [],
        typeObjects: [],
        cityStart: [],
        cityEnd: []
      },
      selectsFilter: {
        ads: [],
        typeOrder: [],
        typeObjects: [],
        cityStart: [],
        cityEnd: []
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              this.$modal.show('editTracker')
              this.dataForModal = row

              this.selectsFromModal.typeVehicles.push(
                this.selectsDataForModal.typeVehicles.find(
                  (value) =>
                    value.vehicle_types__id ==
                    this.dataForModal.vehicle_types__id
                ) || {
                  vehicle_types__code:
                    this.selectsDataForModal.typeVehicles[0]
                      .vehicle_types__code,
                  vehicle_types__id: null
                }
              )
              // console.log(this.dataForModal.vehicles__id, this.objectsVehiclesNew.findIndex(
              //     (el) =>
              //     el.vehicles__gosnumber === this.dataForModal.vehicles__gosnumber
              //   ));
              // this.objectsVehiclesNew.forEach(el => console.log(el.vehicles__gosnumber))
              if (this.dataForModal.vehicles__id) {
                this.selectsFromModal.objectsVehicle = [
                  {
                    vehicles__gosnumber: this.dataForModal.vehicles__gosnumber,
                    vehicles__id: this.dataForModal.vehicles__id,
                    vehicles__name: this.dataForModal.vehicles__name
                  }
                ]
              } else {
                this.selectsFromModal.objectsVehicle = [
                  {
                    vehicles__gosnumber: '',
                    vehicles__id: null,
                    vehicles__name: 'Не назначен'
                  }
                ]
              }

              // console.log(this.objectsVehiclesNew);
            }
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (
            column.field ===
            Object.keys(row.settings).find((x) => x == column.field)
          ) {
            return row.settings[column.field]
          }
        }
      }
    }
  },
  methods: {
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },

    updateValue(data) {
      this.updatedValues[data.name] = data.value
    },

    sendSms() {
      if (
        Object.values(this.senderSmsPreset).length &&
        this.activeSelectRowsMultiple.length
      ) {
        Promise.all([
          this.activeSelectRowsMultiple.map((id) => {
            return GeneralService.editOrCreate(`/sms`, {
              phone: this.dataTable.find((x) => x.contracts__id == id)
                .clients__phone,
              text: this.senderSmsPreset.sms_presets__text
            })
          })
        ]).then((data) => {
          this.$toast.success('Выполнено')
          this.$modal.hide('sendSmsPreset')
          this.senderSmsPreset = null
          this.selectsFromModal.sms_presets = []
          this.activeSelectRowsMultiple = []
          console.log(data)
        })
      } else {
        this.$toast.error('Не выбран договор или не выбран пресет')
      }
    },

    computedTranslate(values, tranaslate) {
      let keys = Object.keys(values)
      let tranaslateLocal = tranaslate
      keys.forEach((keyName) => {
        tranaslateLocal = tranaslateLocal.replace(
          `\\${keyName}\\`,
          values[keyName]
        )
      })
      return tranaslateLocal
    },

    async loadExel(urlToFetch) {
      let dataFrom = await httpInstance.get('/contracts/table/main/xlsx', {
        responseType: 'blob'
      })
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },

    saveEditModal() {
      GeneralService.edit(`/trackers/${this.dataForModal.gps__id}`, {
        ...this.updatedValues
      })
        .then((data) => {
          this.$modal.hide('editTracker')
          this.loadData()
        })
        .catch((e) => {
          this.selectsFromModal.owners = []
          this.$toast.error(e.response.data.message)
        })
    },

    onBuyTracker() {
      if (this.newValueCustomModal) {
        GeneralService.editOrCreate(`/bids`, {
          request_services__type_service_id: 10,
          count: this.newValueCustomModal
        })
          .then((data) => {
            this.$modal.hide('buyTracker')
            this.$modal.show('successOfBuyTracker')
            this.loadData()
          })
          .catch((e) => {
            this.$modal.hide('buyTracker')
            this.$toast.error(e.response.data.message)
          })
      }
    },

    onBuyTranslateTracker() {
      if (this.newValueCustomModal) {
        GeneralService.editOrCreate(`/bids`, {
          request_services__type_service_id: 11,
          count: this.newValueCustomModal
        })
          .then((data) => {
            this.$modal.hide('buyTranslateTracker')
            this.$modal.show('successOfBuyTracker')
            this.loadData()
          })
          .catch((e) => {
            this.$modal.hide('buyTranslateTracker')
            this.$toast.error(e.response.data.message)
          })
      }
    },

    onAddBalance() {
      if (this.newValueCustomModal) {
        GeneralService.edit(`/trackers/balance`, {
          amount: this.newValueCustomModal
        })
          .then((data) => {
            this.$modal.hide('addBalance')
            this.$modal.show('successOfAddBalance')
            this.loadData()
          })
          .catch((e) => {
            this.$modal.hide('addBalance')
            this.$modal.show('errorOfAddBalance')
            this.$toast.error(e.response.data.message)
          })
      }
    },

    toggleTrackerHandler() {
      GeneralService.edit(`/trackers/statuses/update`, {
        trackersIds: this.activeSelectRowsMultiple,
        active: this.activateTab == this.dataLang['tracker_text13'] ? 0 : 1
      })
        .then((data) => {
          this.$modal.hide('acceptToggleTracker')
          if (this.activateTab == this.dataLang['tracker_text13']) {
            this.$modal.show('successOfDisabledTracker')
          } else {
            this.$modal.show('successOfActivateTracker')
          }

          this.loadData()
        })
        .catch((e) => {
          this.activeSelectRowsMultiple = []
          if (e.response.data.errorCode == 10) {
            this.dataOfErrors = e.response.data.data
            this.$modal.show('errorOfActivateTracker')
          }

          this.$modal.hide('acceptToggleTracker')
        })
    },

    resetFilter() {
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.$refs.topFilter
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
      this.filter = [
        { field: 'contracts__status_id', value: [4], operator: '!=' }
      ]
      this.selectsFilter.trackers = []
      this.selectsFilter.statuses = []
      ;(this.filterTimes = {
        contractsCreateStart: '',
        contractsCreateEnd: '',
        startsRateTime: '',
        endRateTime: '',
        startsRateFinishTime: '',
        endRateFinishTime: ''
      }),
        this.loadData()
    },

    onCloseModal() {
      this.currentId = null
      this.updatedValues = {}
      this.dataForModal = {}
      this.activeSelectRowsMultiple = []
      this.selectsFromModal.objectsVehicle = []
      this.selectsFromModal.typeVehicles = []
    },
    deleteActionAndModal() {
      if (this.activeSelectRowsMultiple.length) {
        this.$modal.show('acceptDelete')
      } else {
        this.$modal.show('withoutOptionsModal')
      }
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
    },
    deleteAcceptRow() {
      GeneralService.delete(`/trackers/` + this.activeSelectRowsMultiple)
        .then((data) => {
          this.loadData()
          this.$modal.hide('acceptDeleteModal')
          this.$modal.show('successDeleteTracker')
        })
        .catch((e) => {
          console.log(e)
          this.$modal.hide('acceptDeleteModal')
          this.$modal.show('errorDeleteTracker')
        })
    },
    loadData() {
      this.localLoading = true
      Promise.all([
        GeneralService.get(`/contracts/filter/ads`)
          .then((data) => {
            this.selectsData.ads = data.data.data
            // this.selectsFilter.ads = data.data.data.rows
            console.log(data);
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error(
              'Неизвестная ошибка при загрузке источников рекламы'
            )
          }),
        GeneralService.get(`/contracts/filter/order-types`)
          .then((data) => {
            this.selectsData.typeOrder = data.data.data.rows
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка при загрузке типов аренды')
          }),

        GeneralService.get(`/presets/sms`, {
          params: {
            where: JSON.stringify([
              { field: 'sms_presets__category_id', value: 1 }
            ])
          }
        })
          .then((data) => {
            console.log(data)
            this.selectsDataForModal.sms_presets = data.data.data.rows
            console.log(this.selectsDataForModal)
          })
          .catch((e) => {
            console.log(e)
          })
      ]).then(() => {
        console.log(this.computedDatesStartsContract)
        GeneralService.get(`/contracts/table/main`, {
          params: {
            ...this.paginationFinesStateParams,
            ...(this.filter.length && {
              where: JSON.stringify([
                ...this.filter,
                // ...(this.computedDatesCreateContract.field && [this.computedDatesCreateContract]),
                ...(this.computedDatesStartsContract.length && [
                  ...this.computedDatesStartsContract
                ]),
                ...(this.computedDatesFinishContract.length && [
                  ...this.computedDatesFinishContract
                ])
              ])
            })
          }
        })
          .then((data) => {
            this.dataTable = data.data.data.rows
            this.statisticsTopTable = data.data.data.statistics
            this.getFinesAll(data.data.data)
            this.localLoading = false
          })
          .catch((e) => {
            console.log(e)
            this.$toast.error('Неизвестная ошибка при загрузке таблицы')
          })
      })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.operator && { operator: data.operator }),
          ...(data.mod && { mod: data.mod })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
      }
    },

    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('finesPagination', ['getFinesAll'])
  },
  mounted() {},
  created() {
    this.loadData()
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('finesPagination', ['paginationFinesStateParams']),
    ...mapGetters('finesPagination', ['numPages']),

    computedDatesFinishContract() {
      return [
        this.filterTimes.startsRateFinishTime
          ? {
              field: 'contracts__ends',
              value: this.filterTimes.startsRateFinishTime
                .split('.')
                .reverse()
                .join('-'),

              operator: '>='
            }
          : {},
        this.filterTimes.endRateFinishTime
          ? {
              field: 'contracts__ends',
              value: this.filterTimes.endRateFinishTime
                .split('.')
                .reverse()
                .join('-'),

              operator: '<='
            }
          : {}
      ]
    },

    computedDatesStartsContract() {
      return [
        this.filterTimes.startsRateTime
          ? {
              field: 'contracts__starts',
              value: this.filterTimes.startsRateTime
                .split('.')
                .reverse()
                .join('-'),

              operator: '>='
            }
          : {},
        this.filterTimes.endRateTime
          ? {
              field: 'contracts__starts',
              value: this.filterTimes.endRateTime
                .split('.')
                .reverse()
                .join('-'),

              operator: '<='
            }
          : {}
      ]
    },
    computedDatesCreateContract() {
      if (
        this.filterTimes.contractsCreateStart ||
        this.filterTimes.contractsCreateEnd
      ) {
        return {
          field: 'contracts__created_at',
          value: [
            this.filterTimes.contractsCreateStart
              .split('.')
              .reverse()
              .join('-'),
            this.filterTimes.contractsCreateEnd.split('.').reverse().join('-')
          ],
          operator: 'between',
          mod: 'date'
        }
      } else {
        return {}
      }
    },

    computedCurrentTabForPath() {
      if (this.activateTab == this.dataLang['tracker_text13']) {
        return 'active'
      } else {
        return 'disabled'
      }
    },

    columns() {
      return [
        {
          field: '',
          key: 'a6',
          type: 'checkbox',
          title: '',
          width: '2%',
          align: 'center'
        },
        {
          title: this.dataLang['reg_contract_edit_31'],
          children: [
            {
              field: 'contracts__id',
              key: '1636',
              title: this.dataLang['reg_contract_main_33'],
              align: 'center',
              width: '2%',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div class={'h-100'}>
                    <div style={'height: 10px'}></div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'contracts__id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'contracts__created_at',
              key: '15373',
              title: this.dataLang['reg_contract_main_34'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.contracts__created_at
                      ? dayjs(row.contracts__created_at).format(
                          `${this.userSetting.company__tempalte_date} HH:mm`
                        )
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'ads__name',
              key: '1721',
              title: this.dataLang['reg_contract_main_35'],
              align: 'center',
              width: '6%',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.ads}
                      onInput={(e, w) => {
                        console.log(e)
                        if (!this.filter.find((x) => x.field == 'ads__id')) {
                          this.filter.push({
                            field: 'ads__id',
                            value: e.id
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex((x) => x.field == 'ads__id')
                          ].value = e.id
                        }
                      }}
                      style={'width: 100%'}
                      label={'name'}
                      placeholder={''}
                      id={'id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData.ads}
                    ></BaseSelect>
                  </div>
                )
              }
            },
            {
              field: 'employees__name',
              key: '137246',
              title: this.dataLang['reg_contract_main_36'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['reg_contract_main_27'],
          children: [
            {
              field: 'order_types__name',
              key: '165315',
              title: this.dataLang['reg_contract_main_37'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },

              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.order_types__name]}</span>
              }
            },
            {
              field: 'vehicle_types__code',
              key: '1631411',
              title: this.dataLang['reg_contract_main_38'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.vehicle_types__code]}</span>
              }
            },
            {
              field: 'vehicles__gosnumber',
              key: '163315',
              title: this.dataLang['reg_contract_main_39'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.vehicles__name} {row.vehicles__gosnumber}
                  </span>
                )
              }
            }
          ]
        },

        {
          title: this.dataLang['reg_contract_main_28'],
          children: [
            {
              field: 'clients__name',
              key: '126341',
              title: this.dataLang['reg_contract_main_40'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            },
            {
              field: 'clients__balance',
              key: '163161',
              title: this.dataLang['reg_contract_main_41'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            }
          ]
        },

        {
          title: this.dataLang['reg_contract_main_29'],
          children: [
            {
              field: 'contracts__starts',
              key: '1631261',
              title: this.dataLang['reg_contract_main_42'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.contracts__starts
                      ? dayjs(row.contracts__starts).format(
                          `${this.userSetting.company__tempalte_date} HH:mm`
                        )
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'cities_start__name',
              key: '16311261',
              title: this.dataLang['reg_contract_main_43'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_places_start__address',
              key: '16312261',
              title: this.dataLang['reg_contract_main_44'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['reg_contract_main_30'],
          children: [
            {
              field: 'contracts__ends',
              key: '163351261',
              title: this.dataLang['reg_contract_main_42'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.contracts__ends
                      ? dayjs(row.contracts__ends).format(
                          `${this.userSetting.company__tempalte_date} HH:mm`
                        )
                      : ''}
                  </span>
                )
              }
            },
            {
              field: 'cities_end__name',
              key: '99261',
              title: this.dataLang['reg_contract_main_43'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            },
            {
              field: 'vehicle_places_end__address',
              key: '163312261',
              title: this.dataLang['reg_contract_main_44'],
              align: 'center',
              width: '6%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['reg_contract_main_31'],
          children: [
            {
              field: 'contracts__duration',
              key: '1633514261',
              title: this.dataLang['reg_contract_main_45'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.contracts__duration}{' '}
                    {this.dataLang['reg_contract_edit_4']}.
                  </span>
                )
              }
            },
            {
              field: 'contracts__run',
              key: '163315261',
              title: this.dataLang['reg_contract_main_46'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            },
            {
              field: 'contracts__total',
              key: '1633122261',
              title: this.dataLang['reg_contract_main_47'],
              align: 'center',
              width: '4%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                  </div>
                )
              }
            }
          ]
        },

        {
          field: 'contract_statuses__status',
          key: '1633122461',
          title: this.dataLang['reg_contract_main_32'],
          align: 'center',
          width: '6%',

          ellipsis: {
            showTitle: false,
            lineClamp: 1
          },
          renderHeaderCell: ({ column }, h) => {
            return (
              <div>
                <div class={'text-center'}>{column.title}</div>
              </div>
            )
          },
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.dataLang[row.contract_statuses__status]}.</span>
          }
        }

        // "rows": [
        //         {
        //             "contracts__id": 102,
        //             "contracts__created_at": "2025-02-06 11:04:15",
        //             "ads__name": "Internet",
        //             "employees__name": "Администратор компании 2",
        //             "order_types__name": "orders_type_rent_text1",
        //             "vehicle_types__code": "car",
        //             "vehicles__id": 6,
        //             "vehicles__name": "Volkswagen Polo",
        //             "vehicles__gosnumber": "В961МС799",
        //             "clients__id": 4,
        //             "clients__phone": "+7(001)000-00-02",
        //             "clients__name": "Тест клиент компании 2",
        //             "clients__balance": "-4272.00",
        //             "clients__closed_contracts": "Н",
        //             "contracts__starts": "2025-02-01 21:00:00",
        //             "cities_start__name": "Тула",
        //             "vehicle_places_start__id": null,
        //             "vehicle_places_start__address": null,
        //             "vehicle_places_start__phone": null,
        //             "contracts__ends": "2025-02-21 21:00:00",
        //             "cities_end__name": "Тула",
        //             "vehicle_places_end__id": null,
        //             "vehicle_places_end__address": null,
        //             "vehicle_places_end__phone": null,
        //             "contracts__duration": 21,
        //             "contracts__run": 0,
        //             "contracts__total": "49450.00",
        //             "contract_statuses__status": "contract_status_reserved",
        //             "place_deliveries_start__id": null,
        //             "place_deliveries_end__id": null,
        //             "contracts_is_simple": true,
        //             "contracts__company_id": 2,
        //             "contracts__company_id_object": 2,
        //             "contracts__company_id_place": 2,
        //             "contracts__company_id_super": 1,
        //             "contracts__is_editable": 1,
        //             "settings": {
        //                 "contracts__run": "cell-status-red"
        //             }
        //         },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.top-line-custom {
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: white;
  width: 65%;
}
</style>
