<template>
  <div class="position-relative">
    <BaseLoader v-if="localLoading"></BaseLoader>
    <BaseTopLine>
      <div class="d-flex gap-10">
        <div
          class="d-flex flex-column align-items-center border border-white py-5 px-10 text-white fsc-11 flex-grow-1"
          v-if="!localLoading"
        >
          <span>{{ dataLang['orders_page_text3'] }}</span>
          <div class="wp-100 d-flex justify-content-between">
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.car.total_count !== infopanel.car.open_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign1'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.car.total_count == infopanel.car.open_count,
                  'bg-danger':
                    infopanel.car.total_count !== infopanel.car.open_count
                }"
              >
                {{ infopanel.car.open_count }}/{{ infopanel.car.total_count }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.commercial.open_count !==
                infopanel.commercial.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign2'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.commercial.open_count ==
                    infopanel.commercial.total_count,
                  'bg-danger':
                    infopanel.commercial.open_count !==
                    infopanel.commercial.total_count
                }"
              >
                {{ infopanel.commercial.open_count }}/{{
                  infopanel.commercial.total_count
                }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.bike.open_count !== infopanel.bike.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign3'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.bike.open_count == infopanel.bike.total_count,
                  'bg-danger':
                    infopanel.bike.open_count !== infopanel.bike.total_count
                }"
              >
                {{ infopanel.bike.open_count }}/{{ infopanel.bike.total_count }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.trailer.open_count !== infopanel.trailer.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign4'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.trailer.open_count ==
                    infopanel.trailer.total_count,
                  'bg-danger':
                    infopanel.trailer.open_count !==
                    infopanel.trailer.total_count
                }"
              >
                {{ infopanel.trailer.open_count }}/{{
                  infopanel.trailer.total_count
                }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.air.open_count !== infopanel.air.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign5'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.air.open_count == infopanel.air.total_count,
                  'bg-danger':
                    infopanel.air.open_count !== infopanel.air.total_count
                }"
              >
                {{ infopanel.air.open_count }}/{{ infopanel.air.total_count }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.track.open_count !== infopanel.track.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign8'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.track.open_count == infopanel.track.total_count,
                  'bg-danger':
                    infopanel.track.open_count !== infopanel.track.total_count
                }"
              >
                {{ infopanel.track.open_count }}/{{
                  infopanel.track.total_count
                }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.water.open_count !== infopanel.water.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign6'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.water.open_count == infopanel.water.total_count,
                  'bg-danger':
                    infopanel.water.open_count !== infopanel.water.total_count
                }"
              >
                {{ infopanel.water.open_count }}/{{
                  infopanel.water.total_count
                }}
              </div>
            </div>
            <div
              class="d-flex flex-column align-items-center w-70"
              :title="[
                infopanel.spec.open_count !== infopanel.spec.total_count
                  ? 'Внимание! Сейчас не все обьекты реестра открыты для глобальной шахматки. Некоторые объекты не видны отрасли! Проверьте у каждого объекта: раздел “Настройка аренды” - есть минимум одна галочка справа, модерация - зеленая, статус “ГОТОВ”. Если вам нужна помощь - нажмите на кнопку “Поддержка'
                  : 'Сейчас все обьекты реестра открыты для глобальной шахматки! Это значит, что все ваши объекты видят и сдают все пользователи и агрегаторы платформы!'
              ]"
            >
              <span>{{ dataLang['orders_page_sign7'] }}</span>
              <div
                class="wp-100 text-center mt-5"
                :class="{
                  order_status_normal_green:
                    infopanel.spec.open_count == infopanel.spec.total_count,
                  'bg-danger':
                    infopanel.spec.open_count !== infopanel.spec.total_count
                }"
              >
                {{ infopanel.spec.open_count }}/{{ infopanel.spec.total_count }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center border border-white p-10 text-white fsc-11"
          v-if="!localLoading"
        >
          <span>{{ dataLang['orders_page_text1'] }}</span>
          <div class="d-flex gap-10 align-items-center mt-5">
            <span>{{ dataLang['orders_page_text4'] }}</span>
            <label class="form-switch ps-0">
              <input
                type="checkbox"
                :checked="!settingInfoPanel.company_settings__autoconfirm_order"
                @input="
                  (e) =>
                    updateValue(
                      !e.target.checked,
                      'company_settings__autoconfirm_order'
                    )
                "
              />
              <i></i>
            </label>
            <span>{{ dataLang['orders_page_text5'] }}</span>
          </div>
        </div>
        <div
          class="d-flex flex-column align-items-center border border-white p-10 text-white fsc-11"
          v-if="!localLoading"
        >
          <span>{{ dataLang['orders_page_text2'] }}</span>
          <div
            class="d-flex gap-30 align-items-center mt-5 base-checkbox-custom"
          >
            <BaseCheckboxV2
              placeholder=""
              class="text-white"
              input-name="employee_profiles__is_active_order_notification_sound"
              v-model="
                settingInfoPanel.employee_profiles__is_active_order_notification_sound
              "
              @oncheckeds="(data) => updateValueEmployee(data.value, data.name)"
            >
              <span class="text-white">{{
                dataLang['orders_page_text6']
              }}</span>
            </BaseCheckboxV2>
            <BaseCheckboxV2
              placeholder=""
              class="text-white"
              input-name="employee_profiles__is_active_order_notification_sms"
              v-model="
                settingInfoPanel.employee_profiles__is_active_order_notification_sms
              "
              @oncheckeds="(data) => updateValueEmployee(data.value, data.name)"
              ><span class="text-white">{{
                dataLang['orders_page_text101']
              }}</span></BaseCheckboxV2
            >
          </div>
        </div>
      </div>
    </BaseTopLine>
    <div class="position-relative h-100 container-fluid">
      <!-- <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div> -->
      <div>
        <div class="d-flex justify-content-between py-15">
          <div class="d-flex gap-10 align-items-center">
            <div
              class="base-button base-button--action"
              @click="$modal.show('ordersModal', { mode: 'create' })"
            >
              <img
                :src="require(`@/assets/icons/add.svg`)"
                width="13.5"
                height="13.5"
              />
              <span class="fsc-14">{{
                dataLang['orders_page_btn_text4']
              }}</span>
            </div>
            <div class="base-button base-button--action" @click="toArchive">
              <img
                :src="require(`@/assets/icons/to-folder.svg`)"
                width="18"
                height="18"
              />
              <span class="fsc-14">
                {{ dataLang['orders_page_btn_text5'] }}</span
              >
            </div>
            <div class="base-button base-button--action" @click="acceptDelete">
              <img
                :src="require(`@/assets/icons/adddel.svg`)"
                width="13.5"
                height="13.5"
              />
              <span class="fsc-14">{{
                dataLang['orders_page_btn_text6']
              }}</span>
            </div>
            <!-- <div class="base-button base-button--action">
          <img
            :src="require(`@/assets/icons/add-new.svg`)"
            width="16"
            height="16"
          />
          <span class="fsc-14">{{ dataLang['orders_page_btn_text7'] }}</span>
        </div> -->

            <!-- <div class="base-button base-button--action" @click="">
          <img
            :src="require(`@/assets/icons/to-exel.svg`)"
            width="16"
            height="16"
          />
          <span class="fsc-14">в Excel</span>
        </div> -->
          </div>
          <div class="d-flex gap-15">
            <a
              class="base-button base-button--action"
              href="https://news.cigarexpert.ru/"
              target="_blank"
            >
              <span class="fsc-14">{{
                dataLang['orders_page_btn_text2']
              }}</span>
            </a>
            <div class="d-flex align-items-center gap-15">
              <div
                class="base-button base-button--action"
                @click="$modal.show('modalSmsPresets')"
              >
                <span class="fsc-14">{{
                  dataLang['orders_page_btn_text1']
                }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center gap-15">
              <div
                class="base-button base-button--action"
                @click="$modal.show('modalSendSomeSMS')"
              >
                <span class="fsc-14">{{
                  dataLang['orders_page_btn_text3']
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex gap-15 fsc-11" v-if="!localLoading">
          <div>
            <span
              >{{ dataLang['orders_page_text7'] }}:
              <span class="fw-bold">{{ countOrders }}</span>
            </span>
          </div>
          <div>
            <span
              >{{ dataLang['orders_page_text8'] }}:
              <span class="fw-bold">{{ statistics.new.count }}</span> ({{
                statistics.new.percent.toFixed(1)
              }}%)</span
            >
          </div>
          <div>
            <span
              >{{ dataLang['orders_page_text9'] }}:
              <span class="fw-bold">{{ statistics.permanent.count }}</span> ({{
                statistics.permanent.percent.toFixed(1)
              }}%)</span
            >
          </div>
          <div>
            <span
              >{{ dataLang['orders_page_text10'] }}:
              <span class="fw-bold">{{ statistics.super.count }}</span> ({{
                statistics.super.percent.toFixed(1)
              }}%)</span
            >
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-15 mb-5">
        <div class="d-flex gap-15 align-items-center">
          <div
            class="btn btn-sm btn-thrity text-white fsc-14"
            @click="loadData(1)"
          >
            {{ dataLang['register_btn_filter_on'] }}
          </div>
          <div
            class="text-decoration-underline fsc-14 text-dark"
            style="cursor: pointer"
            @click="resetFilter"
          >
            {{ dataLang['register_btn_filter_off'] }}
          </div>
        </div>
        <BasePagination
          :page-count="numPages"
          :pagination-params="paginationOrdersStateParams"
          :showLimitInput="false"
          @updateLimitValue="updateLimitValue"
          :page-range="10"
          :margin-pages="0"
          :break-view-class="''"
          :click-handler="loadData"
          :prev-text="''"
          :next-text="''"
          :container-class="'custom-pagination'"
          :page-class="'custom-pagination__item'"
          :pageLinkClass="'custom-pagination__item-link'"
        >
        </BasePagination>
      </div>
      <ve-table
        style="width: 100%"
        :columns="columns"
        :table-data="dataTable"
        :border-x="true"
        :border-y="true"
        row-key-field-name="orders__id"
        ref="mainTable"
        :eventCustomOption="eventCustomOption"
        :checkbox-option="{
          hideSelectAll: false,
          selectedRowChange: selectedRowChange,
          selectedAllChange: selectedAllChange
        }"
        v-if="!localLoading"
        :clickHighlight="false"
        :cell-style-option="cellStyleOption"
        :rowStyleOption="{
          hoverHighlight: true
        }"
        :cellSelectionOption="{ enable: false }"
      >
      </ve-table>
    </div>
    <BaseModal
      name="acceptDeleteWithPin"
      width="420"
      :clickToClose="false"
      :shiftY="0.5"
      @before-close="pincodeModalHandler"
      :adaptive="true"
      height="auto"
      classes="bg-transparent shadow-none"
    >
      <div
        v-if="localLoading"
        class="position-absolute wp-100 h-100 d-flex justify-content-center align-items-center bg-white"
        style="top: 0; left: 0; z-index: 500"
      >
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <div
        class="modal-base-content modal-content py-20 px-30 rounded-25 bg-white"
        :style="{}"
      >
        <div class="d-flex justify-content-end">
          <slot name="modal-header"></slot>
          <button
            type="button"
            class="btn-close"
            @click="$modal.hide('acceptDeleteWithPin')"
          ></button>
        </div>
        <div
          class="modal-content-dynamic flex-grow-1 d-flex flex-column h-100 align-items-center justify-content-center container-with-input"
        >
          <p class="fw-bold mb-30 text-center">Внимание!</p>

          <div class="d-flex flex-column align-items-center">
            <!-- <div
              class="base-button px-20 rounded-10"
              @click="$modal.hide('acceptDeleteWithPin')"
            >
              {{ dataLang['vehile_modal_btn_reset'] }}
            </div> -->
            <p class="text-center">{{ messageError }}</p>
            <BaseInputNew
              placeholder=""
              :width="'225'"
              class="mb-10"
              :input-name="'pincode'"
              @input="(data) => (pincode = data.value)"
              value=""
              name-sign=""
            >
            </BaseInputNew>
            <div class="d-flex justify-content-end gap-15">
              <div
                @click="$modal.hide('acceptDeleteWithPin')"
                class="btn btn-sm btn-opposite px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_reset'] }}
              </div>
              <div
                @click="acceptDelete"
                class="btn btn-sm btn-primary px-20 rounded-10 d-flex align-items-center justify-content-center"
              >
                {{ dataLang['vehile_modal_btn_save'] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <OrdersModal
      :currentOpenedId="currentOpenedId"
      @updateMainData="loadData"
    />
    <SmsPresetsModal :type-reestr="1" />
    <SendSomeSms />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { VeTable } from 'vue-easytable'
import SelectsService from '@/api/SelectsService'
import GeneralService from '@/api/GeneralService'
import SmsPresetsModal from './orders-modal/SmsPresetsModal.vue'
import OrdersModal from './orders-modal/OrdersModal.vue'
import EventBus from '@/event-bus.js'
import SendSomeSms from '@/components/reuse_modal/SendSomeSms.vue'
import SSEEventSource from '@/sse-instance.js'
export default {
  components: {
    VeTable,
    SendSomeSms,
    OrdersModal,
    SmsPresetsModal
  },

  props: {
    modeOpenModal: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      haveNewOrder: false,
      intervalUpdate: null,
      dataTimeSelect: { orders_from: [], orders_to: [] },
      selectsData: {
        types: [],
        statuses: [],
        ads: []
      },
      selectsFilter: {
        type: [],
        statuses: [],
        type_ts: [],
        type_body: [],
        ads: []
      },
      params: {
        page: 1
      },
      infopanel: {},
      settingInfoPanel: {},
      selects: [],
      statistics: {},
      countOrders: '',
      pincode: '',
      messageError: [],
      localLoading: true,
      dataTable: [],
      activeSelectRowsMultiple: [],
      filter: [],
      updatedValues: {},
      computedAllPage: 1,
      currentPage: 1,
      activePagePagination: {},
      testedloading: false,
      currentOpenedId: null,
      mainModalOpened: false,
      rowStyleOption: {
        stripe: true
      },
      cellSelectionOption: {
        enable: false
      },
      eventCustomOption: {
        bodyRowEvents: ({ row, rowIndex }) => {
          return {
            dblclick: (event) => {
              console.log(event, row, rowIndex)
              this.currentOpenedId = row.orders__id
              this.$modal.show('ordersModal', {
                mode: 'edit',
                id: row.orders__id
              })
            }
          }
        }
      },
      cellStyleOption: {
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (column.field == 'order_statuses__status') {
            return row.order_statuses__status
          }
          if (!row.order_ext__is_owner) {
            return 'order_status_confirmed'
          }
          if (
            column.field == 'clients__fullname' &&
            (row.clients__balance < 0 || row.clients__is_blacklisted == 1 || row.clients__is_globalblacklisted == 1)
          ) {
            return 'order_status_new'
          }
        }
      },
      limitPaginationValue: 10
    }
  },
  methods: {
    soundClick() {
      var audio = new Audio() // Создаём новый элемент Audio
      audio.src = this.userSetting.order_sound // Указываем путь к звуку "клика"
      audio.play() // Автоматически запускаем

      console.log('its works')
    },
    updateValue(data, name) {
      this.updatedValues[name] = data
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.requestToPath, 2000)
    },
    updateValueEmployee(data, name) {
      this.updatedValues[name] = data
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.requestToPathEmployee, 2000)
    },
    requestToPathEmployee() {
      GeneralService.edit(
        '/employees/' + this.userSetting.employee_id,
        this.updatedValues
      )
        .then((data) => {
          this.$toast.success(data.data.message)
          this.updatedValues = {}
        })
        .catch((e) => {
          console.log(e)
        })
    },
    requestToPath() {
      GeneralService.edit('/settings', this.updatedValues)
        .then((data) => {
          // this.selects.push(data.data.data.rows)
          this.$toast.success(data.data.message)
          this.updatedValues = {}
        })
        .catch((e) => {
          console.log(e)
          // console.log(e.response.status)
          // if (e.response.status == 409) {
          //   this.$modal.show('acceptDeleteWithPin')
          // }
        })
    },
    updateLimitValue(data) {
      this.limitPaginationValue = data

      this.loadData(1)
      // this.getTransportVehiclesAll({
      //   page: 1,
      //   limit: this.limitPaginationValue,
      // })
    },
    sendData(callback, nameTab, oldTab, newTab, flagIsClose) {
      EventBus.$emit('contentLoaded')
      EventBus.$emit('updateContentData')
      callback(true, nameTab, oldTab, newTab, flagIsClose)
    },
    async loadExel() {
      let dataFrom = await GeneralService.toExcel(this.currentOpenedId, {
        where: JSON.stringify(this.filter)
      })
      console.log(dataFrom)
      const filename = new Date().toISOString()
      const url = window.URL.createObjectURL(dataFrom.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    selectedRowChange({ row, isSelected, selectedRowKeys }) {
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    selectedAllChange({ row, isSelected, selectedRowKeys }) {
      console.log(selectedRowKeys)
      this.activeSelectRowsMultiple = selectedRowKeys
    },
    acceptDelete() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.delete('/orders/' + this.activeSelectRowsMultiple, {
          ...(this.pincode && { pincode: this.pincode })
        })
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
            this.$modal.hide('acceptDeleteWithPin')
          })
          .catch((e) => {
            console.log(e.response.status)
            if (e.response.status == 409) {
              this.$modal.show('acceptDeleteWithPin')
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
            } else if (e.response.status == 400) {
              this.messageError = e.response.data.message
              this.$toast.error(e.response.data.message)
            }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    toArchive() {
      if (this.activeSelectRowsMultiple.length) {
        GeneralService.get(
          '/orders/to/archive/' + this.activeSelectRowsMultiple,
          {}
        )
          .then((data) => {
            // this.selects.push(data.data.data.rows)
            console.log(data)
            this.loadData(1)
            this.$toast.success(data.data.message)
          })
          .catch((e) => {
            console.log(e)
            // console.log(e.response.status)
            // if (e.response.status == 409) {
            //   this.$modal.show('acceptDeleteWithPin')
            // }
          })
      } else {
        this.$toast.error('Выберите хотя-бы одно поле')
      }
    },
    // urlToQuaries: {
    //   deep: true,
    //   get() {
    //     return {
    //       url: `/orders/table/archive`,
    //       params: {
    //         limit: this.limitPaginationValue,
    //         page: this.params.page,
    //         ...(this.filter.length && { where: JSON.stringify(this.filter) })
    //       }
    //     }
    //   },
    //   set(newNumPage) {
    //     this.params.page = newNumPage
    //   }
    // },

    // urlToQuaries(newNumPage) {
    //   return {
    //     url: `/orders/table/main`,
    //     params: {
    //       limit: this.limitPaginationValue,
    //       page: this.currentPage,
    //       ...(this.filter.length && { where: JSON.stringify(this.filter) })
    //     }
    //   }
    // },
    loadData(newNumPage) {
      this.localLoading = true
      this.currentPage = newNumPage
      Promise.all([
        GeneralService.get(
          `/employees/${this.userSetting.employee_id}?join=profile,fields=employee_profiles__is_active_order_notification_sound,employee_profiles__is_active_order_notification_sms`
        )
          .then((data) => {
            console.log(data)
            this.settingInfoPanel = {
              ...this.settingInfoPanel,
              ...data.data.data.rows[0]
            }
          })
          .catch((e) => console.log(e))
      ]).then(() => {
        //   return {
        //   url: `/orders/table/main`,
        //   params: {
        //     limit: this.limitPaginationValue,
        //     page: this.currentPage,
        //     ...(this.filter.length && { where: JSON.stringify(this.filter) })
        //   }
        // }
        GeneralService.get('/orders/table/main', {
          params: {
            ...this.paginationOrdersStateParams,
            ...(this.filter.length && { where: JSON.stringify(this.filter) })
          }
        })
          .then((data) => {
            this.getOrdersAll(data.data.data)
            this.countOrders = data.data.data.count
            this.activePagePagination = data.data.data
            this.computedAllPage = data.data.data.total / data.data.data.limit
            this.dataTable = data.data.data.rows
            this.statistics = data.data.data.statistics_by_order
            this.activeSelectRowsMultiple = []
            this.localLoading = false
            this.haveNewOrder = data.data.data.is_new_orders
          })
          .catch((e) => console.log(e))
      })
    },
    resetFilter() {
      // this.selectsData
      this.filter = []
      this.loadData(1)
      console.log(this.selectsData, this.$refs.serviceTable)
      this.selectsFilter = {
        type: [],
        statuses: []
      }
      this.dataTimeSelect = {
        orders_from: [],
        orders_to: []
      }
      this.$refs.mainTable.$el
        .querySelectorAll('input.base-input--new')
        .forEach((elem, index) => {
          elem.value = ''
        })
    },
    fillFilter(data) {
      let newValueFilter =
        data.type == 'like' ? `;percent;${data.value};percent;` : data.value
      if (!this.filter.find((x) => x.field == data.name)) {
        this.filter.push({
          field: data.name,
          value: newValueFilter,
          ...(data.type == 'like' && { operator: 'like' }),
          ...(data.mod == 'phone' && { mod: 'phone' })
        })
      } else {
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value =
          newValueFilter
        console.log(this.filter)
      }
      console.log(this.filter, data)
    },
    pincodeModalHandler() {
      this.pincode = ''
      this.activeSelectRowsMultiple = []
      this.messageError = ''
    },
    fillDateFilter(data) {
      // [{"field":"orders__created_at", "value":["2024-01-04", "2024-01-15"], "operator":"between", "mod":"date"}]
      let transformData = data.value
        ? data.value.split('.').reverse().join('-')
        : ''
      if (!this.filter.find((x) => x.field == data.name)) {
        let indexedArray = []
        indexedArray[data.index] = transformData
        console.log('create')
        this.filter.push({
          field: data.name,
          value: indexedArray,
          operator: 'between',
          mod: 'date'
        })
      } else {
        console.log(
          this.filter[this.filter.findIndex((x) => x.field == data.name)].value
        )
        this.filter[this.filter.findIndex((x) => x.field == data.name)].value[
          data.index
        ] = transformData
        console.log('transform')
      }
      console.log(this.filter)
    },
    prepareLabelsForDefaultSelects(data, keyDataFromFetch, toTranslate) {
      for (const key in data) {
        data[key].forEach((element) => {
          element['label'] = element[Object.keys(element).pop()]
          element['key'] = key
          element['value'] = Object.keys(element)[0]
          element['selectName'] = keyDataFromFetch
        })
      }
      return data
    },
    ...mapActions('orderPagiation', ['getOrdersAll'])
  },
  mounted() {
    EventBus.$on('updateContentData', () => {
      this.loadData(1)
    })
    EventBus.$on('reopenModal', (data) => {
      console.log(data, '1231fewefwef wd wqd qwq dq qwd qw')
      setTimeout(() => {
        this.$modal.show('ordersModal', { id: data.id, mode: 'edit' })
      }, 500)
    })

    // this.$modal.show('ordersModal', { mode: 'create' })
  },
  created() {
    Promise.all([
      // /orders/infopanel
      GeneralService.get(
        `/orders?fields=ads__name, order_ext__ad_id&uniqueBy=order_ext__ad_id`
      )
        .then((data) => {
          console.log(data, 'get ads')
          // this.selects.push(data.data.data.rows)
          this.selectsData.ads = data.data.data.rows
        })
        .catch((e) => {
          console.log(e)
        }),
      GeneralService.get(`/objects/types`)
        .then((data) => {
          // this.selects.push(data.data.data.rows)
          this.selectsData = Object.assign(this.selectsData, {
            objects_types: data.data.data.rows
          })
        })
        .catch((e) => {
          console.log(e)
        }),
      SelectsService.getAll()
        .then((data) => {
          this.selectsData.types = this.prepareLabelsForDefaultSelects(
            data.data.data.rows,
            'allSelects',
            true
          )
          console.log(this.selectsData)
        })
        .catch((e) => console.log(e)),
      GeneralService.get('orders/statuses')
        .then((data) => {
          console.log(data)
          this.selectsData.statuses = data.data.data.rows
          console.log(this.selectsData)
          // statuses
        })
        .catch((e) => console.log(e)),
      // /settings?fields=company_settings__autoconfirm_order
      GeneralService.get('orders/infopanel')
        .then((data) => {
          data.data.data.forEach((elem, index) => {
            this.infopanel[elem.type_code] = elem
          })
        })
        .catch((e) => console.log(e)),
      GeneralService.get('settings')
        .then((data) => {
          this.settingInfoPanel = {
            ...this.settingInfoPanel,
            ...data.data.data.rows[0]
          }
        })
        .catch((e) => console.log(e)),
      GeneralService.get(
        `/employees/${this.userSetting.employee_id}?join=profile,fields=employee_profiles__is_active_order_notification_sound,employee_profiles__is_active_order_notification_sms`
      )
        .then((data) => {
          console.log(data)
          this.settingInfoPanel = {
            ...this.settingInfoPanel,
            ...data.data.data.rows[0]
          }
        })
        .catch((e) => console.log(e))
    ]).then(() => {
      this.loadData(1)
    })
    // SSEEventSource.addEventListener('newOrder', (event) => {
    //   this.loadData(1)
    //   this.$toast.success('У вас новый заказ')

    //   if (
    //     this.settingInfoPanel
    //       .employee_profiles__is_active_order_notification_sound ||
    //     this.updatedValues.employee_profiles__is_active_order_notification_sound
    //   ) {
    //     this.soundClick()
    //   }
    // })
    this.intervalUpdate = setInterval(() => {
      this.loadData(1)
      if (
        (this.settingInfoPanel
          .employee_profiles__is_active_order_notification_sound ||
          this.updatedValues
            .employee_profiles__is_active_order_notification_sound) &&
        this.haveNewOrder
      ) {
        this.soundClick()
        this.haveNewOrder = false
      }
    }, 60000)
  },
  beforeRouteLeave(to, from, next) {
    // SSEEventSource.close()
    this.intervalUpdate = null
    next()
  },
  computed: {
    ...mapState('translationModule', ['dataLang']),
    ...mapState('userSettingModule', ['userSetting']),
    ...mapState('orderPagiation', ['paginationOrdersStateParams']),
    ...mapGetters('orderPagiation', ['numPages']),
    urlToQuaries() {
      console.log(this.limitPaginationValue, this.currentPage)
      return {
        url: `/orders/table/main`,
        params: {
          limit: this.limitPaginationValue,
          page: this.currentPage,
          ...(this.filter.length && { where: JSON.stringify(this.filter) })
        }
      }
    },
    columns() {
      return [
        {
          title: this.dataLang['orders-page__table__header_order_text1'],
          children: [
            {
              field: '',
              key: 'a',

              type: 'checkbox',
              title: '',
              width: 5,
              align: 'center'
            },
            {
              field: 'orders__id',
              key: '12',
              title: this.dataLang['orders-page__table__header_order_text2'],
              align: 'left',
              width: '3%',
              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'orders__id'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },

            {
              field: 'orders__created_at',
              key: '13',
              title: this.dataLang['orders-page__table__header_order_text3'],
              align: 'left',
              width: '5%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },

              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.orders__created_at
                      ? this.$time(row.orders__created_at)
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'ads__name',
              key: '14',
              title: this.dataLang['orders-page__table__header_order_text4'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.ads}
                      onInput={(e, w) => {
                        console.log(e)
                        if (!this.filter.find((x) => x.field == 'ads__name')) {
                          this.filter.push({
                            field: 'ads__name',
                            value: e.ads__name
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex((x) => x.field == 'ads__name')
                          ].value = e.ads__name
                        }
                      }}
                      style={'width: 100%'}
                      label={'ads__name'}
                      placeholder={''}
                      id={'ads__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData.ads}
                    ></BaseSelect>
                    {/* <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'ads__name'}
                      value={''}
                      nameSign={''}
                    /> */}
                  </div>
                )
              }
            },
            {
              field: 'orders__type_rent',
              key: '15',
              title: this.dataLang['orders-page__table__header_order_text5'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.type}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'orders__type_rent'
                          )
                        ) {
                          this.filter.push({
                            field: 'orders__type_rent',
                            value: e.value
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'orders__type_rent'
                            )
                          ].value = e.value
                        }
                      }}
                      style={'width: 100%'}
                      label={'label'}
                      placeholder={''}
                      id={'cities__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData.types.orders__type_rent}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.orders__type_rent]}</span>
              }
            },
            {
              field: 'orders__vehicle_type',
              key: '16',
              title: this.dataLang['orders-page__table__header_order_text6'],
              align: 'left',
              width: '8%',

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.type_ts}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'orders__vehicle_type'
                          )
                        ) {
                          this.filter.push({
                            field: 'orders__vehicle_type',
                            value: e.vehicle_types__code
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'orders__vehicle_type'
                            )
                          ].value = e.vehicle_types__code
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      customLabel={({ vehicle_types__code }) => {
                        return this.dataLang[vehicle_types__code]
                      }}
                      id={'order_statuses__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData?.objects_types}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.orders__vehicle_type]}</span>
              }
            },
            {
              field: 'orders__body_generation',
              key: '222',
              title: this.dataLang['orders-page__table__header_order_text7'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.type_body}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'orders__body_generation'
                          )
                        ) {
                          this.filter.push({
                            field: 'orders__body_generation',
                            value: e.value
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'orders__body_generation'
                            )
                          ].value = e.value
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      label={'label'}
                      id={'order_statuses__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData.types?.car_examples__body_type}
                    ></BaseSelect>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return <span>{this.dataLang[row.orders__body_generation]}</span>
              }
            },
            {
              field: 'orders__start_city_name',
              key: '23',
              title: this.dataLang['orders-page__table__header__order_text8'],
              align: 'left',
              width: 90,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'orders__start_city_name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__end_city_name',
              key: '24',
              title: this.dataLang['orders-page__table__header_order_text9'],
              align: 'left',
              width: 90,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'orders__end_city_name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__starts',
              key: '25',
              title: this.dataLang['orders-page__table__header_order_text10'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.orders__starts
                      ? this.$time(row.orders__starts)
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'orders__ends',
              key: '33',
              title: this.dataLang['orders-page__table__header_order_text11'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.orders__ends
                      ? this.$time(row.orders__ends)
                          .format(
                            `${this.userSetting.company__tempalte_date} HH:mm`
                          )
                      : ''}
                  </span>
                )
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            },
            {
              field: 'vehicles__fullname',
              key: '34',
              title: this.dataLang['orders-page__table__header_order_text12'],
              align: 'left',
              width: 120,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'vehicles__fullname'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'orders__duration',
              key: '35',
              title: this.dataLang['orders-page__table__header_order_text13'],
              align: 'left',
              width: 30,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return row.orders__duration + ' ' + 'сут.'
              }
            },
            {
              field: 'orders__cost',
              key: '36',
              title: this.dataLang['orders-page__table__header_order_text14'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['orders-page__table__header_client_text1'],
          children: [
            {
              field: 'clients__phone',
              key: '37',
              title: this.dataLang['orders-page__table__header_client_text2'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like', mod: 'phone' }}
                      inputName={'clients__phone'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            },
            {
              field: 'clients__fullname',
              key: '121',
              title: this.dataLang['orders-page__table__header_client_text3'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'clients__fullname'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            }
          ]
        },

        {
          title: this.dataLang['orders-page__table__header_timer_text1'],
          children: [
            {
              field: 'order_ext__employee_id',
              key: '123',
              title: this.dataLang['orders-page__table__header_timer_text2'],
              align: 'left',
              width: 20,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <div style={'height: 19.5px'}></div>
                  </div>
                )
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return ''
              }
            },
            {
              field: 'order_statuses__status',
              key: '122',
              title: this.dataLang['orders-page__table__header_timer_text3'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return this.dataLang[row.order_statuses__status]
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>
                    <BaseSelect
                      vModel={this.selectsFilter.statuses}
                      onInput={(e, w) => {
                        console.log(e)
                        if (
                          !this.filter.find(
                            (x) => x.field == 'orders__status_id'
                          )
                        ) {
                          this.filter.push({
                            field: 'orders__status_id',
                            value: e.order_statuses__id
                          })
                        } else {
                          this.filter[
                            this.filter.findIndex(
                              (x) => x.field == 'orders__status_id'
                            )
                          ].value = e.order_statuses__id
                        }
                      }}
                      style={'width: 100%'}
                      placeholder={''}
                      customLabel={({ order_statuses__status }) => {
                        return this.dataLang[order_statuses__status]
                      }}
                      id={'order_statuses__id'}
                      selectLabel={''}
                      deselectLabel={''}
                      options={this.selectsData?.statuses}
                    ></BaseSelect>
                  </div>
                )
              }
            }
          ]
        },
        {
          title: this.dataLang['orders-page__table__header_e_text1'],
          children: [
            {
              field: 'employees__name',
              key: '124',
              title: this.dataLang['orders-page__table__header_order_text2'],
              align: 'left',
              width: 70,

              ellipsis: {
                showTitle: true,
                lineClamp: 1
              },
              renderHeaderCell: ({ column }, h) => {
                return (
                  <div>
                    <div class={'text-center'}>{column.title}</div>

                    <BaseInputNew
                      placeholder=''
                      style={'width: 100%'}
                      disabled={false}
                      onInput={this.fillFilter}
                      additionalParams={{ type: 'like' }}
                      inputName={'employees__name'}
                      value={''}
                      nameSign={''}
                    />
                  </div>
                )
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

<style>
 {
  /* order_status_new	cell-status-danger	новый КРАСНЫЙ
order_status_archive	order-status-archive	архивный СЕРЫЙ
order_status_consulation	cell-status-warning	консультация ОРАНЖЕВЫЙ
order_status_security	order-status-security	проверка сб СИНИЙ
order_status_contract	order-status-contract	договор ЗЕЛЕНЫЙ
order_status_deffered		отложенный ФИОЛЕТОВЫЙ
order_status_confirmed		подтверждение объекта ЖЕЛТЫЙ
order_status_fill_anket		анкетирование ГОЛУБОЙ
order_status_payment		оплата клиентом САЛАТОВЫЙ */
}
.order_status_new {
  background-color: red !important;
}
.order_status_archive {
  background-color: gray !important;
}
.order_status_consulation {
  background-color: orange !important;
}
.order_status_security {
  background-color: blue !important;
}
.order_status_contract {
  background-color: #23b14d !important;
}
.order_status_deffered {
  background-color: rgb(100, 100, 161) !important;
}
.order_status_confirmed {
  background-color: yellow !important;
}
.order_status_fill_anket {
  background-color: lightblue !important;
}
.order_status_payment {
  background-color: rgb(71, 255, 71) !important;
}
.order_status_normal_green {
  background-color: #23b14d !important;
}
.base-checkbox-custom .base-checkbox__fill-square {
  background-color: white;
}
.form-switch {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  padding-left: 0;
}

.form-switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: white;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.form-switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}

.form-switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #23b14d;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
}

.form-switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}
</style>
